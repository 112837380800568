<template>
    <Entete/>
    <Homeslide/>
    <FullwidthproduitVue/>
  <!--shipping area start-->
<section>
    <!-- <div class="container"> -->
        
        <div class="col-12 mb-50" style="background-color:orange; height:140px; ">
            <br>
            <div class="row">
                <div class="col-lg-3 col-md-6">
                    <div class="single_shipping_box">
                        <div class="shipping_box_icone">
                            <i class="zmdi zmdi-local-shipping zmdi-hc-fw"></i>
                        </div>
                        <div class="shipping_box_content">
                            <h3>Livraison</h3>
                            <p>Rapide et Sécurisé</p>
                        </div>
                    </div>
                </div>
                <div class="col-lg-3 col-md-6">
                    <div class="single_shipping_box">
                        <div class="shipping_box_icone">
                            <i class="zmdi zmdi-replay-5"></i>
                        </div>
                        <div class="shipping_box_content">
                            <h3>Retours 3 jours max</h3>
                            <p>Remboursement Garantie</p>
                        </div>
                    </div>
                </div>
                <div class="col-lg-3 col-md-6">
                    <div class="single_shipping_box">
                        <div class="shipping_box_icone">
                            <i class="zmdi zmdi-phone-in-talk"></i>
                        </div>
                        <div class="shipping_box_content">
                            <h3>Disponible 18/7</h3>
                            <p>Consultations</p>
                        </div>
                    </div>
                </div>
                <div class="col-lg-3 col-md-6">
                    <div class="single_shipping_box">
                        <div class="shipping_box_icone">
                            <i class="zmdi zmdi-run"></i>
                        </div>
                        <div class="shipping_box_content">
                            <h3>99% Satisfaction</h3>
                            <p>Clients</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    <!-- </div> -->
</section>
<!--shipping area end-->

<!--home product area start-->
<section class="home_product_area home_product_three mb-50">
        <div class="container">
            <div class="row">
                <div class="col-12">
                   <div class="product_header">
                        <div class="section_title section_title_two">
                            <h2>PRODUITS PAR DEPARTEMENT</h2>
                        </div>
                         <div class="product_tab_button">
                            <ul class="nav" role="tablist">
                              <!-- <div v-if="1 == 2 || (1 + 2 == 3 && 4 == 4)">I am visible!</div> -->
                                <li v-for="(departement, i) in departements" :key="i">
                                    <a  data-toggle="tab" role="tab" @click="getproduitbydepartement(departement.id)" aria-selected="true"> 
                                        {{ departement.departement }}
                                    </a>
                                    <!-- v-if="i == false" class="active"
                                    <a v-if="i == true" data-toggle="tab" style="text-decoration: none;" role="tab"  aria-selected="false">
                                        {{depart.departement}}
                                    </a> -->
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div class="tab-content">
                      <div class="tab-pane fade show active">
                        <div style="display: flex; flex-direction: column; align-items: center; justify-content: center;">
                          <br><br><br>
                          <img v-if="produitbydepartements == false" src="assets/loader_orange.gif" width="100px" alt="">
                        </div>
                        <div class="row product_column4 owl ">
                            <div v-for="(produitd,index) in produitbydepartements" :key="index" class="single_product col-lg-3 col-md-4 col-6">
                                <div class="product_thumb">
                                    <div style="height: 250px; overflow: hidden;"><router-link :to="`/produit/${produitd.id}`"> <img :src="`https://apiy.htvann.com/${produitd.cover_img}`" alt=""> </router-link></div>

                                    <div v-if="produitd.rabais > 0" class="label_product">
                                        <span class="label_sale">Rabais {{produitd.rabais}} %</span>
                                    </div>

                                    <!-- <div class="quick_button">
                                        <a href=""  title="quick view"> <i class="zmdi zmdi-eye"></i></a>
                                    </div> -->
                                </div>
                                <div class="product_content">
                                    <div class="product_name" style="height: 35px; overflow: hidden;">
                                        <!-- <h3 ><router-link :to="{name: 'produit', query: { idp: 'tets'}}"> {{produitd.sounom}} </router-link></h3> -->
                                        <h3 ><router-link :to="`/produit/${produitd.id}`"> {{produitd.sounom}} </router-link></h3>
                                    </div>
                                    <div class="product_rating">
                                        <ul>
                                            <li><a href="#"><i class="zmdi zmdi-star-outline"></i></a></li>
                                            <li><a href="#"><i class="zmdi zmdi-star-outline"></i></a></li>
                                            <li><a href="#"><i class="zmdi zmdi-star-outline"></i></a></li>
                                            <li><a href="#"><i class="zmdi zmdi-star-outline"></i></a></li>
                                            <li><a href="#"><i class="zmdi zmdi-star-outline"></i></a></li>
                                        </ul>
                                    </div>

                                    <div v-if="produitd.rabais > 0 " class="price_box">
                                        <span class="current_price">{{produitd.prix-((produitd.prix * produitd.rabais)/100)}} HTG</span>
                                        <span class="old_price">{{produitd.prix}} HTG</span>
                                    </div>

                                    <div v-if="produitd.rabais == 0 " class="price_box">
                                        <span class="current_price">{{produitd.prix}} HTG</span>
                                    </div>

                                    <div class="action_links">
                                        <ul>
                                          <!-- <li class="wishlist"><a href="wishlist.html" title="Add to Wishlist"><i class="fa fa-heart-o" aria-hidden="true"></i></a></li> -->
                                          <li class="add_to_cart"><router-link :to="`/produit/${produitd.id}`"><i class="zmdi zmdi-shopping-cart-plus"></i> Ajouter </router-link></li>
                                            <!-- <li class="compare"><a href="#" title="compare"><i class="zmdi zmdi-swap"></i></a></li> -->
                                        </ul>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>  
                    </div> 
                    
                </div>
            </div>
        </div>
    </section>
    <!--home product area end-->

  <Bannerhorizontal/>
  <Piedpage/>
</template>

<script>
import axios from 'axios'
import Entete from './Entete.vue'
import Homeslide from './Homeslide.vue';
import Bannerhorizontal from './Bannerhorizontal.vue';
import Piedpage from './Piedpage.vue';
import FullwidthproduitVue from './Fullwidthproduit.vue';

export default {
  name:'HomeView',components: {
    Entete,
    Homeslide,
    Bannerhorizontal,
    FullwidthproduitVue,
    Piedpage,
  },
  data () {
    return {
      produits: null,
      departements : null,
      produitbydepartements : null,
      loading: true,
      errored: false
    }
  },
  
  mounted() {
    axios
      .get('https://apiy.htvann.com/api/produits/topcho')
      .then((response) => {
        this.produits = response.data;
      });

      axios.get('https://apiy.htvann.com/api/departements')
      .then((response) => {
        this.departements = response.data;
        // console.log(this.departements)
      this.getproduitbydepartement(this.departements[0].id)
      });
  },
 methods: {
  getproduitbydepartement(departe_id){
    this.produitbydepartements = [];
    axios.get('https://apiy.htvann.com/api/produits/departement?departement_id='+departe_id)
      .then((response) => {
        this.produitbydepartements = response.data.Produits;
        // console.log(this.produitbydepartements)

      });
  },
  getTest(){

  }
 },

};

</script>
