<template>
  <div class="banner_area banner_three mb-50">
        <div class="container">
            <div class="row">
                <div class="col-12">
                    <div class="single_banner">
                        <div class="banner_thumb">
                            <a href="shop.html"><img src="assets/img/bg/banner4.jpg" alt=""></a>
                        </div>
                       
                    </div>
                </div>         
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name:'Bannerhorizontal',
};
</script>

<style>

</style>