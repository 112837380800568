<template>
   <!-- modal area start-->
   <div class="modal fade" id="modal_box_login" tabindex="-1" role="dialog"  aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered-md" role="document">
            <div class="modal-content">
                <button class="close" data-dismiss="modal" aria-label="Close">
                  <span aria-hidden="true">&times;</span>
                </button>
                <div class="modal_body">
                    <div class="container">
                        <div class="row">
                             <!--login area start-->
                            <div class="col-lg-12 col-md-12">
                                <div class="account_form">
                                    <h2>Connectez-vous</h2>
                                    <form action="#">
                                        <p>   
                                            <label>E-mail <span>*</span></label>
                                            <input type="text" v-model="username">
                                        </p>
                                        <p>   
                                            <label>Mot de Passe <span>*</span></label>
                                            <input type="password" v-model="pwd">
                                            <small style="color:red;">{{ loginmsg }}</small>
                                        </p>   
                                        <div class="login_submit">
                                        <a href="#">Mot de passe oublié?</a>
                                            <!-- <label for="remember">
                                                <input id="remember" type="checkbox">
                                                Remember me
                                            </label> -->
                                            <button type="button"  @click="login()">login</button>
                                            
                                        </div>

                                    </form>
                                </div>    
                            </div>
                            <!--login area start-->
                        </div>     
                    </div>
                </div>    
            </div>
        </div>
    </div>

     <!-- modal area end-->
</template>

<script>
import axios from 'axios'
// import router from '@/router'

export default {
    data() {
        return {
            username: null,
            pwd: null,
            loginmsg: '',
            body: null,
            apiUrl: 'https://apiy.htvann.com/api/',
            UserAuth: null,
            Boutique: null,
        }
    },
    mounted() {
        
    },methods: {
        login(){
            this.loginmsg = "Patientez svp...";
            if(this.pwd && this.username){
                this.body = {
                    mail: this.username,
                    password: this.pwd
                };
                // console.log(this.body);
                axios.post(this.apiUrl+'auth/login',this.body).then((response)=>{
                    // console.log(response);
                    if(response.status==200){
                        
                        if(response.data.utilisateur){
                            if(response.data.utilisateur.role_id == 1){
                                this.UserAuth = JSON.stringify(response.data.utilisateur);
                                localStorage.setItem('UserAuth',JSON.stringify(response.data.utilisateur));
                                localStorage.setItem('token',response.data.token);
                                // this.$router.push('/');
                                window.location.reload();
                            }else if(response.data.utilisateur.role_id == 2){
                                this.UserAuth = JSON.stringify(response.data.utilisateur);
                                localStorage.setItem('UserAuth',JSON.stringify(response.data.utilisateur));
                                localStorage.setItem('token',response.data.token);
                                axios.get(this.apiUrl+'boutique/user?utilisateur_id='+response.data.utilisateur.id,{headers: {'content-Type': 'application/json','Authorization': 'Bearer '+response.data.token}}).then((response)=>{
                                    
                                    this.Boutique = JSON.stringify(response.data[0]);
                                    localStorage.setItem('Boutique',JSON.stringify(response.data[0]));
                                    window.location.reload();
                                });
                            }else{
                                this.loginmsg = "Compte non compatible";
                            }
                        }
                        if(response.data.login == false){
                            this.loginmsg = "Identifiant ou Mot de passe Incorrect";
                            // console.log(this.loginmsg);
                        }

                    }else{
                        this.loginmsg = 'Identifiant ou Mot de passe Incorrect';
                    }
                });
            }else{
                this.loginmsg = 'Entrez Identifiant et Mot de passe';
            }

        },
        mergePanier(){
            this.getPanierGuest();
            if(localStorage.Panier){
                JSON.parse(localStorage.Panier).forEach(element => {
                    this.Panier.push(element);
                    this.PrixTotal = this.PrixTotal + element.total;
                    this.addtopanier(element,element.quantite_ajouter);
                });
            }
            
            
        },
        addtopanier(produit,qte){

            if(this.UserAuth){
            // alert('ok');
                let body = {
                    utilisateur_id:this.UserAuth.id,
                    produit_id:produit['id'],
                    couleur_id:this.couleurselect,
                    size_id:this.sizeselect,
                quantite_ajouter: this.Qte,
                };
                axios.post(this.apiUrl+'panier/ajouter',body,{headers: {'content-Type': 'application/json','Authorization': 'Bearer '+this.token}})
                .then((response)=>{
                    if(response.status === 200){
                        router.push('/panier');
                    }
                });

            }

        },
    },

}
</script>

<style>

</style>