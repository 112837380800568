<template>
  <Entete/>
    <!-- my account start  -->
    <section class="main_content_area">
        <div class="container">   
            <div class="account_dashboard product_column3">
                <div class="row">
                    <div class="col-lg-6 offset-lg-3 col-md-6 offset-md-3" >
                        <h4>Création Boutique</h4>
                        <br/>
                        <div class="form-group">
                            <label>Le nom de la Boutique</label>
                            <input type="text" v-model="boutique" v-on:blur="verifyboutique()" class="form-control"/>
                            <p class="text-danger"> {{ boutiquemsg }} </p>
                        </div>
                        <div class="form-group">
                            <label>Le slogan</label>
                            <input type="text" v-model="slogan" v-on:blur="verifyslogan()" class="form-control"/>
                            <p class="text-danger"> {{ sloganmsg }} </p>
                        </div>
                        <div class="form-group">
                            <label>Description</label>
                            <textarea type="text" v-model="apropos" v-on:blur="verifyapropos()" class="form-control"/>
                            <p class="text-danger"> {{ aproposmsg }} </p>
                        </div>
                        <div class="form-group">
                            <button @click="saveboutiques()" class="btn btn-info float-right">Enregistrer</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <BannerhorizontalVue/>
    <PiedpageVue/>
</template>

<script>
import axios from 'axios';
import Entete from './Entete.vue';
import BannerhorizontalVue from './Bannerhorizontal.vue';
import PiedpageVue from './Piedpage.vue';

export default {
    components: {
        Entete,
        PiedpageVue,
        BannerhorizontalVue
    },data() {
        return {
            apiUrl: 'https://apiy.htvann.com/api/',
            UserAuth: JSON.parse(localStorage.UserAuth),
            token: localStorage.token,
            boutique: null,
            boutiquemsg: '',
            slogan: null,
            sloganmsg: null,
            apropos: null,
            aproposmsg: null,
            formverify: false,
        }
    },mounted() {
        
    },
    methods: {
        verifyboutique(){
            if(this.boutique){
                this.boutiquemsg = '';
                this.formverify = true;
            }else{
                this.boutiquemsg = "Entrer le nom de la boutique";
                this.formverify = false;
            }
        },verifyslogan(){
            if(this.slogan){
                this.sloganmsg = '';
                this.formverify = true;
            }else{
                this.sloganmsg = "Entrer le slogan";
                this.formverify = false;
            }
        },
        verifyapropos(){
            if(this.apropos){
                this.aproposmsg = '';
                this.formverify = true;
            }else{
                this.aproposmsg = "Entrer la description de la boutique";
                this.formverify = false;
            }
        },
        saveboutiques(){
            if(this.formverify){
                const body = {
                    boutique: this.boutique,
                    slogan: this.slogan,
                    apropos: this.apropos,
                    utilisateur_id: this.UserAuth.id,
                };

                axios.post(this.apiUrl+'boutique/creation',body,{headers: {'content-Type': 'application/json','Authorization': 'Bearer '+this.token}})
                .then((response)=>{
                    if(response.status===200){
                        this.$router.push('/maboutique');
                    }else{
                        alert('Erreur')
                    }
                });

            }else{
                alert('Verifier les champs');
            }

        },
    },

}
</script>

<style>

</style>