<template>
    <Entete/>
    <div class="container">
    <br><h3>Mes commandes</h3><hr>
    <div class="col-lg-8 col-md-8 offset-lg-2 offset-ms-2 col-sm-12">
        <div v-for="produit in Commandes" >
            <p v-if="produit.statut_commande == 'En_Attente' ">Commande En Attente... </p>
            <p v-if="produit.statut_commande == 'Echoue' " style="color: red;">Transaction Echouée </p>
            <div v-if="produit.statut_commande == 'Reussie' " style="color: green;">
                <p v-if="produit.livraison == 0 " >Commande Validé </p>
                <p v-if="produit.livraison == 1 " >Colis Ambalé </p>
                <p v-if="produit.livraison == 2 " >Colis Expédidé </p>
                <p v-if="produit.livraison == 3 " >Colis Livré </p>
            </div>
            <div class="row" >
                <div class="col-2" style="display: flex; flex-direction: column; justify-content: space-around; align-items: center;">
                    <img :src="urlImg+produit.cover_img">
                </div>
            <div class="col-9" >
                <h3 style="font-size: small; white-space: normal;">
                <small> {{produit.article}} </small>
                <br>{{produit.sounom}}
              </h3>
              <!-- <h4>Quantité Disponible : {{Produit.stock}} </h4> -->
              <h2 v-if="produit.rabais < 1" style="font-size: small; float: left; margin-right: 5px; color: green;">
                <small style="font-style: italic; color: black;">Unité</small>
                {{produit.prix}} HTG
                <h6 style="color: black; font-size: small;">
                  Quantité : {{produit.quantite}} Total : {{produittotal}}
                </h6>
                <div v-if="produit.livraison != 3">
                  <h6 v-if="produit.prixlivraison <= 1" style="color:blue; font-size: small;">
                    livraison Gratuite
                  </h6>
                  <h6 v-if="produit.prixlivraison > 1" style="color:blue; font-size: small;">
                    livraison : {{produit.prixlivraison}} HTG
                  </h6>
                  <h6>
                    Dans {{produit.jours}} jrs
                  </h6>
                </div>
                <h6>
                  G Total : {{produit.G_total}} HTG
                </h6>
                <h6 v-if="produit.statut_commande == 'Reussie' && produit.livraison != 3 " style="color: green;">
                  <small style="color: black;">Estimation de livraison</small>
                  <br/> Entre <br/>
                  {{ produit.created }}
                  <br/><small style="color: black;">
                    à
                  </small>
                  {{ produit.created }} {{ produit.jours }}
                </h6>
                <h6 v-if="produit.statut_commande == 'Reussie' " >
                  <small>Adresse</small><br/>
                  {{produit.rue}}, {{produit.commune}} , {{produit.geodepartement}}
                </h6>
              </h2>
              <h2 v-if="produit.rabais > 0" style="font-size: small; float: left; margin-right: 5px; color: green;">
                <small style="background-color: #F77D0E;border-radius: 2px; color: #fff; margin-right: 6px; padding: 1px 3px 0; font-size:small; font-style: italic; ">
                  Rabais : {{produit.rabais}} %
                </small><br/>
                <small style="font-size: small; font-style: italic; color: black;">Unité</small>
                <small style="font-size: small; font-style: italic; color: black; text-decoration: line-through;">
                  {{Intl.NumberFormat().format(produit.prix)}} HTG
                </small>
                <br/>{{Intl.NumberFormat().format(produit.prixrabais)}} HTG
                <h6 style="color: black; font-size: small;">
                  Quantité : {{produit.quantite}} Total : {{produit.total}}
                </h6>
                <h6 v-if="produit.prixlivraison <= 1" style="color:blue; font-size: small;">
                  livraison Gratuite
                </h6>
                <h6 v-if="produit.prixlivraison > 1" style="color:blue; font-size: small;">
                  livraison : {{Intl.NumberFormat().format(produit.prixlivraison)}} HTG
                </h6>
                <h6>
                  Dans {{produit.jours}} jrs
                </h6>
                <h6>
                  G Total : {{Intl.NumberFormat().format(produit.G_total)}} HTG
                </h6>
                <h6 v-if="produit.statut_commande == 'Reussie' " style="color: green;">
                  <small style="color: black;">Estimation de livraison</small>
                  <br/> Entre <br/>
                  {{ produit.created }}
                  <br/><small style="color: black;">
                    à
                  </small><br/>
                  {{ produit.created,produit.jours }}
                </h6>
                <h6 v-if="produit.statut_commande == 'Reussie' ">
                  <small>Adresse</small><br/>
                  {{produit.rue}}, {{produit.commune}} , {{produit.geodepartement}}
                </h6>
              </h2>
              <div class="col-12" style="display: flex; justify-content: space-between;">

                <!-- <button (click)="retirer(produit)" style="color: dimgrey; " ion-button clear float-left>Retirer</button>
                <button (click)="ajouter(produit)" ion-button clear float-right>Ajouter</button> -->
              </div>
            </div>
          </div>
        </div>
        <!-- <div v-if="Commandes.length > 5" style="display:flex; justify-content: space-between;" padding>
          <b style="margin-top:5px;">Total : {{PrixTotal | number : '2.1-2'}} HTG</b>
          <button (click)="methodepaiement(Commandes)" ion-button round small outline float-right>Placer Commande</button>
        </div> -->
    </div>
    </div>
    <Bannerhorizontal/>
    <Piedpage/>
</template>

<script>
import axios from 'axios'
import Entete from './Entete.vue';
import Bannerhorizontal from './Bannerhorizontal.vue';
import Piedpage from './Piedpage.vue';

export default {
    components: {
        Entete,
        Piedpage,
        Bannerhorizontal
      },
    data() {
        return {
            urlImg: 'https://apiy.htvann.com/',
            apiUrl: 'https://apiy.htvann.com/api/',
            UserAuth: JSON.parse(localStorage.UserAuth),
            token: localStorage.token,
            adresse: null,
            Commandes: [],
            livraison: null,
            Distance: null,
            DureeLivraison: null,
            Prixdistance: null,
            Prixvolume: null,
            Prixpoid: null,
            Qte: null,
            msg: null,
            JoursStrg: null,
            Mois: null,
            succes: null,
            dange: null,
            warn: null,
            PrixTotal: 0,
            TotalaPayer: 0,
        }
    },
    created() {
      console.log('okok');
        this.commandes();
        // this.verifierCommande();
        // if(this.Commandes.length > 0){
        //   this.verifierCommande();
        //   console.log('okok');
        // }
    },
    methods: {
        
        commandes(){
            axios.get(this.apiUrl+'commandes/user?user_id='+this.UserAuth.id,{headers: {'content-Type': 'application/json','Authorization': 'Bearer '+this.token}})
            .then((response)=>{
                // console.log(response);
                // console.log(response.data.Commandes);
                if(response.status === 200){
                      this.Commandes = [];
                      // this.PrixTotal = 0;
                      response.data.Commandes.forEach(element => {
                      this.Commandes.push(element);
                      this.PrixTotal = this.PrixTotal + element.G_total;
                    });
                    this.verifierCommande();
                    console.log(this.Commandes);
                }
            });

        },
        commandesautoload(){

            this.apiservices.getsource((ApiData)=>{
            console.log(ApiData.Commandes);
            if(ApiData.content){
                this.Commandes = [];
                // this.PrixTotal = 0;
                ApiData.Commandes.forEach(element => {
                this.Commandes.push(element);
                this.PrixTotal = this.PrixTotal + element.G_total;
                });
            }
            },'commandes/user?user_id='+this.user.id);

        },
        verifierCommande(){
          // console.log('test verification commande');
            this.Commandes.forEach(element => {
                if(element.statut_commande == "En_Attente" && element.methode_transaction == "MonCash" && element.type_transaction == "Achat"){
                axios.get(this.apiUrl+'transaction/verifier?montant='+element.G_total+'&transactionht='+element.transactionhtId,{headers: {'content-Type': 'application/json','Authorization': 'Bearer '+this.token}}).then((response)=>{
                  console.log(response);
                });
                }

            });

            this.Commandes = []; this.PrixTotal = 0;
            axios.get(this.apiUrl+'commandes/user?user_id='+this.UserAuth.id,{headers: {'content-Type': 'application/json','Authorization': 'Bearer '+this.token}})
            .then((response)=>{
                if(response.status === 200){
                      response.data.Commandes.forEach(element => {
                      this.Commandes.push(element);
                      this.PrixTotal = this.PrixTotal + element.G_total;
                    });
                }
            });
            
        },
        trt_date(commandeDate,departJours){
            commandeDate = new Date(commandeDate);
            console.log(commandeDate);
            // let date = commandeDate;
            commandeDate.setDate( commandeDate.getDate() + departJours );
            console.log(commandeDate);
            // const str_datee = date(commandeDate, 'EEEEEE-dd-MM-yyyy');
            const str_datee = commandeDate.setDate( commandeDate.getDate() + departJours );
            // console.log(str_date);
            this.Mois =this.getMoisAbrg();
            this.JoursStrg =this.getJoursStr();
            // var date = str_datee.split('-');
            // console.log(date);

            return this.JoursStrg[date[0]]+' '+date[1]+' '+this.Mois[parseInt(date[2])]+' '+date[3];

        },getJours() {

          this.Jours =[
            {
              1:"Lunidi",
              2:"Mardi",
              3:"Mercredi",
              4:"Jeudi",
              5:"Vendredi",
              6:"Samedi",
              7:"Dimanche"
            }
          ];
          return this.Jours;

        },getJoursStr() {

          this.Jours =
            {
              'Mo':"Lunidi",
              'Tu':"Mardi",
              'We':"Mercredi",
              'Th':"Jeudi",
              'Fr':"Vendredi",
              'Sa':"Samedi",
              'Su':"Dimanche"
            };
          return this.Jours;

        },getMois() {
            this.Mois =
              {
                1:"Janvier",
                2:"Fevrier",
                3:"Mars",
                4:"Avril",
                5:"Mai",
                6:"Jun",
                7:"Juillet",
                8:"Aout",
                9:"Septembre",
                10:"Octobre",
                11:"Novembre",
                12:"Decembre"
              };
            return this.Mois;
        }, getMoisAbrg() {
            this.Mois =
              {
                1:"Jan",
                2:"Fev",
                3:"Mar",
                4:"Avr",
                5:"Mai",
                6:"Jun",
                7:"Juil",
                8:"Aout",
                9:"Sept",
                10:"Oct",
                11:"Nov",
                12:"Dec"
              }
            ;
            return this.Mois;
        }

    },

}
</script>

<style>

</style>