<template>
    <Entete/>
  <div class="col-12" style="height:50px;"></div>
    <!--contact area start-->
    <div class="contact_area">
      <div class="container">
          <div class="row">
              <div class="col-lg-6 col-md-12">
                 <div class="contact_message content">
                      <h3>Contacter nous</h3>
                       <p>
                         En cas de besoin de savoir plus sur nos différentes services,
                         n'hésitez pas à nous contacter par appel telephonique ou nous laisser un message depuis cette page ou par e-mail,
                         pour toutes informations supplémentaires.
                       </p>
                      <ul>
                          <li><i class="fa fa-home"></i>  Adresse : 177, Faubert, Pétion Ville Ouest, Haïti</li>
                          <li><i class="fa fa-phone"></i> <a href="#">Info@htvann.com</a></li>
                          <li><i class="fa fa-envelope-o"></i> +(509) 44 46 31 45 / 41 83 71 16</li>
                      </ul>
                  </div>
              </div>
              <div class="col-lg-6 col-md-12">
                 <div class="contact_message form">
                      <h3>Parlez nous de votre projet</h3>
                      <form id="contact-form" name="contact" >
                          <p>
                             <label> Nom et Prénom (Obligatoire)</label>
                              <input name="nomprenom"  placeholder="Nom et Prénom *" required minlength="4" type="text">
                              <!-- <div *ngIf="name.invalid && (name.dirty || name.touched)"> Nom Et prénom obligatoire </div> -->
                          </p>
                          <p>
                             <label> E-mail (Obligatoire)</label>
                              <input name="mail"  placeholder="E-mail *" type="email">
                          </p>
                          <p>
                             <label>  Sujet</label>
                              <input name="sujet"  placeholder="Sujet *" type="text">
                          </p>
                          <div class="contact_textarea">
                              <label>  Message</label>
                              <textarea placeholder="Message *" name="message"   class="form-control2" ></textarea>
                          </div>
                          <button > Envoyer </button>
                          <p class="form-messege"></p>
                      </form>
                      <small style="color: green;"> {{msg}} </small>
                  </div>
              </div>
          </div>
      </div>
  </div>

  <!--contact area end-->
    <Bannerhorizontal/>
    <Piedpage/>
</template>

<script>
import Entete from './Entete.vue'
import Bannerhorizontal from './Bannerhorizontal.vue'
import Piedpage from './Piedpage.vue';

export default {
    components: {
        Entete,
        Piedpage,
        Bannerhorizontal
    },
    name:'Contacter',

};
</script>

<style>

</style>