<template>
    <Entete/>
    <div class="container" v-if="Boutique == false && newBoutique == false">
        <br>
        <di class="row col-10 offset-1" >
            <div class="col-12">
                <h3>
                    Ma Boutique
                <h5>Ouvrir votre boutique sur HTVANN et bénéficer des nombreux avantages qu'offre notre plateforme</h5>

                </h3>
                <br>
                <h4>
                    Vendez sur HTVANN c'est vendre plus rapide et en sécurité.
                </h4>
            </div>
            
            <div class="col-12">
                <br><br>
                <button class="btn btn-secondary" @click="creerboutique()">Créer Boutique</button>
            </div>
            
        </di>
    </div>

    <div class="container" v-if="newBoutique != false && Boutique == false">
        <br>
        <di class="row col-10 offset-1" >
            <div class="col-12">
                <h3>
                    Ma Boutique
                <h5>Ouvrir votre boutique sur HTVANN et bénéficer des nombreux avantages qu'offre notre plateforme</h5>

                </h3>
                <br>
                <h4>
                    La boutique {{ newBoutique.boutique }} est en cours d'analyse pour être valider.
                </h4>
            </div>
            
        </di>
    </div>

    <div class="container" v-if="Boutique">
        <div class="col-12" style="text-align:center; padding:30px; margin-top:10px;">
            <h1 style="text-align:center; padding:30px; text-color:orange;">
                {{ Boutique?.boutique }}
            </h1>
            <h4 style="text-color:orange;">{{ Boutique?.slogan }}</h4>
            <small>Partager</small> <br>
            <q-btn>
                <ShareNetwork
                    network="facebook"
                    :url="'https://htvann.com/boutique/'+Boutique?.id*999666333"
                    :title="Boutique?.boutique"
                    :description="Boutique?.slogan"
                    quote=""
                    :hashtags="Boutique?.boutique"
                    
                >
                <a class="facebook">
                    <i class="fa fa-facebook"></i>
                    Facebook
                </a>
                </ShareNetwork>
                &nbsp;&nbsp;&nbsp;
                <ShareNetwork
                    network="whatsapp"
                    :url="'https://htvann.com/boutique/'+Boutique?.id*999666333"
                    :title="Boutique?.boutique"
                    :description="Boutique?.slogan"
                    quote=""
                    :hashtags="Boutique?.boutique"
                >
                <a class="whatsapp">
                    <i class="fa fa-whatsapp"></i>
                    Whatsapp
                </a>
                </ShareNetwork>
                <!-- <ShareNetwork
                    network="messenger"
                    :url="'https://htvann.com/boutique/'+Boutique?.id*999666333"
                    :title="Boutique?.boutique"
                    :description="Boutique?.slogan"
                    quote=""
                    :hashtags="Boutique?.boutique"
                >
                    <a class="messenger">
                        <i class="fa fa-facebook-messenger"></i>
                        Messenger
                    </a>
                </ShareNetwork> -->
                &nbsp;&nbsp;&nbsp;
                <ShareNetwork
                    network="twitter"
                    :url="'https://htvann.com/boutique/'+Boutique?.id*999666333"
                    :title="Boutique?.boutique"
                    :description="Boutique?.slogan"
                    quote=""
                    :hashtags="Boutique?.boutique"
                >
                    <a class="twitter">
                        <i class="fa fa-twitter"></i>
                        Twitter
                    </a>
                </ShareNetwork>
            </q-btn>
        </div>
        <div class="row col-md-10 offset-md-1 col-sm-11 offset-sm-1/2">
            <div class="card col-3">
                <div class="">
                    <b>{{ QteProduit }}</b> <br>
                    <small>Stock</small>
                </div>
            </div>
            <div class="card col-3">
                <div class="">
                    <b>{{ QteVendu }}</b> <br>
                    <small>Vendu</small>
                </div>
            </div>
            <div class="card col-6">
                <div class="">
                    <b>{{ Intl.NumberFormat().format(Caisse) }} htg</b> <br>
                    <small>Caisse</small>
                </div>
            </div>
        </div>
    </div>

    <br>
     <!--product info start-->
     <div class="product_d_info" v-if="Boutique">
        <div class="container">
            <div class="row">
                <div class="col-12">
                    <div class="product_d_inner">
                        <div class="product_info_button">
                            <ul class="nav" role="tablist">
                                <li >
                                    <a class="active" data-toggle="tab" href="#stock" role="tab" aria-controls="stock" aria-selected="false">Stock</a>
                                </li>
                                <li>
                                     <a data-toggle="tab" href="#vente" role="tab" aria-controls="vente" aria-selected="false">Vente</a>
                                </li>
                                <li>
                                     <a data-toggle="tab" href="#caisse" role="tab" aria-controls="caisse" aria-selected="false">Caisse</a>
                                </li>
                                <li>
                                     <a data-toggle="tab" href="#transaction" @click="getdecaissement()" role="tab" aria-controls="transaction" aria-selected="false">Transactions</a>
                                </li>
                            </ul>
                        </div>
                        <div class="tab-content">
                            <div class="tab-pane fade show active" id="stock" role="tabpanel" >
                                <div class="product_info_content">
                                    <div v-for="produit in Stock">
                                        <div class="row" @click="produitdetail(produit)">
                                            <div class="col-2" style="display: flex; flex-direction: column; justify-content: space-around; align-items: center;">
                                            <img :src="urlImg+produit.cover_img">
                                            </div>
                                            <div class="col-9" >
                                            <h3 style="font-size: small; white-space: normal;">
                                                <small> {{produit.article?.article}} </small>
                                                <br/>{{produit.sounom}}
                                            </h3>
                                            <!-- <h4>Quantité Disponible : {{Produit.stock}} </h4> -->
                                            <h2 v-if="produit.rabais < 1" style="font-size: small; float: left; margin-right: 5px; ">
                                                <small style="font-style: italic; color: black;">Unité</small>
                                                {{ Intl.NumberFormat().format(produit.prix) }} HTG
                                                <p>
                                                Ventes : <b style="color: green;">{{produit.vente}}</b> / {{produit.stock}}
                                                &nbsp;&nbsp;
                                                <small v-if="produit.vue > 0"> {{produit.vue}} <ion-icon name="eye"></ion-icon> </small>

                                                </p>
                                            </h2>
                                            <h2 v-if="produit.rabais > 0" style="font-size: small; float: left; margin-right: 5px; ">
                                                <small style="background-color: #F77D0E;border-radius: 2px; color: #fff; margin-right: 6px; padding: 1px 3px 0; font-size:small; font-style: italic; ">
                                                Rabais : {{produit.rabais}} %
                                                </small><br/>
                                                <small style="font-size: small; font-style: italic; color: black;">Unité</small>
                                                <small style="font-size: small; font-style: italic; color: black; text-decoration: line-through;">
                                                {{ Intl.NumberFormat().format(produit.prix) }} HTG
                                                </small>
                                                <br/>{{Intl.NumberFormat().format(produit.prix-(produit.prix*(produit.rabais/100)))}} HTG
                                                <p>
                                                Ventes : <b style="color: green;">{{produit.vente}}</b> / {{produit.stock}}
                                                &nbsp;&nbsp;
                                                <small v-if="produit.vue > 0"> {{produit.vue}} <ion-icon name="eye"></ion-icon> </small>

                                                </p>
                                            </h2>
                                            </div>
                                        </div>
                                        <hr>
                                    </div>
                                </div>
                            </div>
                            <div class="tab-pane fade" id="vente" role="tabpanel" >
                                <div class="product_d_table">
                                    <div v-for="vendu in Vente">
                                        <div class="row" >
                                            <div class="col-2" style="display: flex; flex-direction: column; justify-content: space-around; align-items: center;">
                                                <img :src="urlImg+vendu.cover_img">
                                            </div>
                                            <div class="col-9" >
                                                <b style="font-size: small; white-space: normal;">
                                                    <small> {{vendu.article}} </small>
                                                    <br/>{{vendu.sounom}}
                                                </b>
                                                <br>
                                                <p v-if="vendu.rabais < 1" style="font-size: small; float: left; margin-right: 5px; ">
                                                    <small style="font-style: italic; color: black;">Unité</small>
                                                    {{Intl.NumberFormat().format(vendu.prix)}} HTG
                                                    <br>
                                                    <small v-if="vendu.livraison === 0" style="text-color:green;">En Attente...</small>
                                                    <small v-if="vendu.livraison === 1" style="text-color:green;">Embalé <i class="fa fa-cube"></i></small>
                                                    <small v-if="vendu.livraison === 2" style="text-color:green;">Expédié <i class="fa fa-truck"></i></small>
                                                    <small v-if="vendu.livraison === 3" style="text-color:green;">Livré <i class="fa fa-check"></i></small>
                                                   
                                                </p>
                                                <p v-if="vendu.rabais > 0" style="font-size: small; float: left; margin-right: 5px; ">
                                                    <small style="background-color: #F77D0E;border-radius: 2px; color: #fff; margin-right: 6px; padding: 1px 3px 0; font-size:small; font-style: italic; ">
                                                    Rabais : {{vendu.rabais}} %
                                                    </small><br/>
                                                    <small style="font-size: small; font-style: italic; color: black;">Unité</small>
                                                    <small style="font-size: small; font-style: italic; color: black; text-decoration: line-through;">
                                                    {{Intl.NumberFormat().format(vendu.prix)}} HTG
                                                    </small>
                                                    <br>{{Intl.NumberFormat().format(vendu.prix-(vendu.prix*(vendu.rabais/100)))}} HTG
                                                    <br>
                                                    <small v-if="vendu.livraison === 0" style="text-color:green;">En Attente...</small>
                                                    <small v-if="vendu.livraison === 1" style="text-color:green;">Embalé <i class="fa fa-cube"></i></small>
                                                    <small v-if="vendu.livraison === 2" style="text-color:green;">Expédié <i class="fa fa-truck"></i></small>
                                                    <small v-if="vendu.livraison === 3" style="text-color:green;">Livré <i class="fa fa-check"></i></small>
                                                    
                                                </p>
                                            </div>
                                        </div>
                                        <hr>
                                    </div>
                                </div>
                            </div>
                            <div class="tab-pane fade" id="caisse" role="tabpanel" >
                                <div class="product_d_table">
                                    <div padding>
                                        <br/>
                                        <h4 style="text-align: end;">
                                            <b style="color: green;">{{ Intl.NumberFormat().format(Caisse) }} htg</b>
                                            <br/><small style="font-size: smaller;">Disponibilité de la caisse</small>
                                        </h4>
                                        <br/>
                                        <h6>Detail:</h6><hr/>
                                        <div padding>
                                            <b> Totale Vente : {{ Intl.NumberFormat().format(venteTotal) }} htg</b><br/>
                                            <b> Totale HTVANN Comission : {{ Intl.NumberFormat().format(htvanncmss) }} htg</b><br/>
                                            <b> Totale Décaissement : {{ Intl.NumberFormat().format(totalRequisition) }} htg</b><br/>
                                            <b> Caisse : {{ Intl.NumberFormat().format(Caisse) }} htg</b><br/>
                                        </div>

                                    </div>
                                    <br>
                                    <div>
                                        <button  class="btn btn-success" data-toggle="modal" data-target="#modal_box_decaissemnt">
                                            Faire un Décaissement
                                        </button>
                                    </div>
                                </div>
                            </div>
                            <div class="tab-pane fade" id="transaction" role="tabpanel" >
                                <div class="product">

                                    <table class="table">
                                        <thead>
                                            <th>Méthode</th>
                                            <th>Agent</th>
                                            <th>Refference</th>
                                            <th>Montant</th>
                                            <th>Statut</th>
                                        </thead>
                                        <tbody>
                                            <tr v-for="item in decaissements">
                                                <td>{{item.methode}}</td>
                                                <td>{{item.numero}}</td>
                                                <td v-if="item.reference === null">N/A</td>
                                                <td v-if="item.reference">{{item.reference}}</td>
                                                <td>{{ Intl.NumberFormat().format(item.montant) }} HTG</td>
                                                <td>
                                                    <small v-if='item.statut == 0' style="color:orange;">En Attente</small>
                                                    <small v-if='item.statut == 1' style="color:green;">Validé</small>
                                                    <small v-if='item.statut == 2' style="color:red;">Annullé</small>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!--product info end-->

    <!-- modal decaissement start-->
    <div class="modal fade" id="modal_box_decaissemnt" tabindex="-1" role="dialog"  aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered-md" role="document">
            <div class="modal-content">
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                  <span aria-hidden="true">&times;</span>
                </button>
                <div class="modal_body">
                    <div class="container">
                        <div class="row">
                            <!--decaissement area start-->
                            <div class="col-lg-12 col-md-12">
                                <div class="">
                                    <h2>Décaissement</h2>
                                    <form action="#">
                                        <div class="row">
                                            <div class="col-12">
                                                <p class="col-12">   
                                                    <label>Agent  <span>*</span></label>
                                                    <select class="form-control" v-model="agent" id="">
                                                        <option>Choisir Agent</option>
                                                        <option value="MonCash">MonCash</option>
                                                        <option value="NatCash">NatCash</option>
                                                    </select>
                                                    <small class="text-danger">{{phmsg}}</small>
                                                </p>
                                                <p class="col-12">   
                                                    <label>Téléphone  <span>*</span></label>
                                                    <input class="form-control" type="number" v-model="telephone" >
                                                    <small class="text-danger">{{phmsg}}</small>
                                                </p>
                                                <p class="col-12">   
                                                    <label>Confirmez Téléphone  <span>*</span></label>
                                                    <input class="form-control" type="number" v-model="confirmtelephone" >
                                                    <small class="text-danger">{{phmsg}}</small>
                                                </p>
                                                <p class="col-12">   
                                                    <label>Montant  <span>*</span></label>
                                                    <input class="form-control" type="number" v-model="montant" v-on:blur="montantverify()">
                                                    <small class="text-danger">{{phmsg}}</small>
                                                </p>
                                                <p class="col-12">   
                                                    <label>Confirmez Montant  <span>*</span></label>
                                                    <input class="form-control" type="number" v-model="confirmmontant" v-on:blur="confmontantverify()">
                                                    <small class="text-danger">{{phmsg}}</small>
                                                </p>
                                            </div>
                                            <br>
                                            <div class="col-12" style="display: flex;flex-direction: column;justify-content: center;align-content: center;align-items: center;">
                                                <br>
                                                <button type="button" class="btn btn-success" @click="decaisser()">Enregistrer</button>
                                            </div>
                                        </div>
                                    </form>
                                </div>    
                            </div>
                            <!--decaissement area end-->
                        </div>     
                    </div>
                </div>    
            </div>
        </div>
    </div>
     <!-- modal decaissement end-->
  
    <Bannerhorizontal/>
    <Piedpage/>
</template>

<script>
import axios from 'axios'
import Entete from './Entete.vue'
import Bannerhorizontal from './Bannerhorizontal.vue'
import Piedpage from './Piedpage.vue'

export default {
    components:{
        Entete,
        Bannerhorizontal,
        Piedpage,
    },
    data() {
        return {
            urlImg: 'https://apiy.htvann.com/',
            apiUrl: 'https://apiy.htvann.com/api/',
            UserAuth: JSON.parse(localStorage.UserAuth),
            token: localStorage.token,
            caissenet: 0,
            Produits: [],
            Stock: [],
            mot: '',
            clients: true,
            newBoutique: false,
            Boutique: null,
            QteProduit: null,
            Vente: null,
            venteTotal: null,
            htvanncmss: null,
            QteVendu: null,
            Caisse: null,
            totalRequisition: null,
            decaissements: null,agent: null,telephone: null,montant: '',
        }
    },computed:{
        shareViaWebShare() {
        navigator.share({
            title: tnis.Boutique?.boutique,
            text: tnis.Boutique?.slogan,
            url: 'https://htvann.com/boutique/'+tnis.Boutique?.id*999666333
        });
}
    },
    created() {
        
        if(localStorage.Boutique){
            this.Boutique = JSON.parse(localStorage.Boutique);
            this.getstock();
        }else{
            
            axios.get(this.apiUrl+'boutique/user?utilisateur_id='+this.UserAuth.id,{headers: {'content-Type': 'application/json','Authorization': 'Bearer '+this.token}})
            .then((response)=>{
                
                if (response.data.length > 0) {
                    this.newBoutique = response.data[0];
                    this.Boutique = false;
                }else{

                    this.Boutique = false;

                }     
                

            });
            
        }
    },
    mounted() {
        if(localStorage.Boutique){
            this.Boutique = JSON.parse(localStorage.Boutique);
            this.getstock();
        }else{
          
            axios.get(this.apiUrl+'boutique/user?utilisateur_id='+this.UserAuth.id,{headers: {'content-Type': 'application/json','Authorization': 'Bearer '+this.token}}).then((response)=>{
                if (response.data.lenght > 0) {
                    this.newBoutique = JSON.stringify(response.data[0]);
                    this.Boutique = false;
                }else{

                    this.Boutique = false;

                }     
                

            });
            
        }
    },
    methods: {
        shareViaWebShare() {
            navigator.share({
                title: tnis.Boutique?.boutique,
                text: tnis.Boutique?.slogan,
                url: 'https://htvann.com/boutique/'+tnis.Boutique?.id*999666333
            })
        },
        getstock(){
            axios.get(this.apiUrl+'boutique/produits?boutique_id='+this.Boutique.id,{headers: {'content-Type': 'application/json','Authorization': 'Bearer '+this.token}})
            .then((response)=>{
                // console.log(response.data);
                this.Stock = response.data;
                this.QteProduit = 0; this.QteVendu = 0; this.totalRequisition = 0;
                response.data.forEach(element => {
                    this.QteProduit += element.stock;
                    this.QteVendu = this.QteVendu + element.vente;

                });
                axios.get(this.apiUrl+'boutique/ventes?boutique_id='+this.Boutique.id,{headers: {'content-Type': 'application/json','Authorization': 'Bearer '+this.token}})
                .then((response)=>{
                    console.log(response.data);
                    this.Caisse = response.data.caisse;
                    this.Vente = response.data.ventes;
                    this.venteTotal = response.data.venteTotal
                    this.htvanncmss = response.data.htvanncommussion;
                });
            });

        },
        getdecaissement(){
            axios.get(this.apiUrl+'boutique/decaissements?boutique_id='+this.Boutique.id,{headers: {'content-Type': 'application/json','Authorization': 'Bearer '+this.token}})
            .then((response)=>{
            this.decaissements = response.data.decaissements;
            // console.log(this.decaissements);
            });

        },
        decaisser(){
            const body = {
                    montant : this.montant,
                    boutique_id : this.Boutique.id,
                    methode : this.agent,
                    numero : this.telephone
                  };
            if(this.montant === this.confirmmontant && this.telephone === this.confirmtelephone && this.Caisse >= this.montant){
                axios.post(this.apiUrl+'decaissement/add',body,{headers: {'content-Type': 'application/json','Authorization': 'Bearer '+this.token}})
                .then((response)=>{

                    if(response.status == 200){
                        this.presentToast('Demande envoyée avec succès.');
                    }else{
                        this.presentToast('Demande echouée.');
                    }
                });
            }else{
                alert('Veuillez vérifier le formulaire');
            }
        },
        creerboutique(){
            this.$router.push('/crrbtq');
        }
    },  

}
</script>

<style>

</style>