<template>
   <Entete/>
   <!--about section area -->
   <div class="about_section mt-60">
          <div class="container">
              <div class="row">
                  <div class="col-lg-6 col-md-12">
                      <div class="about_thumb">
                          <img src="img/logo.png" alt="">
                      </div>
                  </div>
                  <div class="col-lg-6 col-md-12">
                      <div class="about_content">
                          <h1>Bienvenue Sur Htvann</h1>
                          <p> Nous sommes une entreprise basant dans le commerce en ligne pour ameliorer les conditions dans lesquelles evolu le marché numerique d'Haïti.  </p>

                      </div>
                  </div>
              </div>
          </div>
      </div>
      <!--about section end-->

    <Bannerhorizontal/>

  <Piedpage/>
</template>

<script>
import Entete from './Entete.vue';
import Bannerhorizontal from './Bannerhorizontal.vue';
import Piedpage from './Piedpage.vue';
export default {
  name:'AboutView',
  components: {
    Entete,
    Bannerhorizontal,
    Piedpage,
  }
};
</script>

<style>

</style>