<template>
   
  <Carousel :wrap-around="true" :breakpoints="breakpoints" :autoplay="3000">
  <Slide v-for="slide in slides" :key="slide">
    <div class="carousel__item">
      <img :src="`${slide.src}`" />
    </div>
  </Slide>
</Carousel>

</template>

<script>
export default {
    name:'Homeslide',
    data() {
        return {
            slides:[
                {src: 'https://htvann.com/img/slides/fashion.png'},
                // {src: 'https://htvann.com/img/slider/mthpmnt.png'},
        ]
        }
    },mounted(){
        // console.log(this.slides);
    }
};

</script>

<style scoped>
.carousel__item{
    width: 100%;
    display: inline-block;
}
</style>