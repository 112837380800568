<template>
    <Entete/>
        <!--product details start-->
    <div class="product_details mt-50 mb-50">
        <div class="container">
            <div class="row">
                <div class="col-lg-6 col-md-6">
                   <div class="product-details-tab">
                        <div id="img-1" class=" single-zoom">
                            <a href="#">
                                <img :src="`https://apiy.htvann.com/${Produit.cover_img}`"  alt="big-1">
                            </a>
                        </div>
                        
                    </div>
                </div>
                <div class="col-lg-6 col-md-6">
                    <div class="product_d_right">
                       <form action="#">
                            <h5> {{Produit.article.article}} </h5>
                            <h1> {{Produit.sounom}} </h1>
                            <router-link :to="`/boutique/${Produit.boutique.id*999666333}`"><h4 style="color:orange;">Boutique {{Produit.boutique.boutique}} </h4></router-link>
                            <!-- <div class="product_nav">
                                <ul>
                                    <li class="prev"><a href="product-details.html"><i class="fa fa-angle-left"></i></a></li>
                                    <li class="next"><a href="product-grouped.html"><i class="fa fa-angle-right"></i></a></li>
                                </ul>
                            </div> -->
                            <div class="product_rating">
                                <ul>
                                    <li><a href="#"><i class="zmdi zmdi-star-outline"></i></a></li>
                                    <li><a href="#"><i class="zmdi zmdi-star-outline"></i></a></li>
                                    <li><a href="#"><i class="zmdi zmdi-star-outline"></i></a></li>
                                    <li><a href="#"><i class="zmdi zmdi-star-outline"></i></a></li>
                                    <li><a href="#"><i class="zmdi zmdi-star-outline"></i></a></li>
                                </ul>
                            </div>
                            <div v-if="Produit.rabais > 0 " class="price_box">
                                <span class="current_price">{{Produit.prix-((Produit.prix * Produit.rabais)/100)}} HTG</span>
                                <span class="old_price">{{Produit.prix}} HTG</span>
                            </div>

                            <div v-if="Produit.rabais == 0 " class="price_box">
                                <span class="current_price">{{Produit.prix}} HTG</span>
                            </div>
                            <div class="product_desc">
                                <h6><b>Détail du produit</b></h6>
                                <p v-html="formatedDescription(Produit.description)"> </p>
                            </div>
                            <div v-if="Produit.sizes.length || Produit.couleurs.length" class="product_variant color">
                                <h3>Options Disponible</h3>

                                <div v-if="Produit.couleurs.length">
                                  <label>couleurs</label>
                                  <ul>
                                      <li v-for="(couleur,index) in Produit.couleurs" :key="index" @click="addcouleur(couleur.id)">
                                        <a href="#" v-if="couleurselect == couleur.id" style="border: solid 1px green; padding:0.5px;">
                                          <img src="{{urlImg}}{{Produit.cover_img}}" alt="zo-th-1"/>
                                        </a>
                                        <a href="#" v-if="couleurselect != couleur.id">
                                          <img src="{{urlImg}}{{Produit.cover_img}}" alt="zo-th-1"/>
                                        </a>
                                      </li>
                                  </ul>
                                </div>

                                <div>
                                  <label>Size</label>
                                  <ul>
                                      <li v-for="(size,index) in Produit.sizes" :key="index" class="" @click="addsize(size.id)">
                                        <div v-if="sizeselect == size.id" style="border: solid 1px green; padding:0.5px;">
                                          {{size.size}}
                                          <small>  Qte {{size.quantite - size.vendu}} </small>
                                        </div>

                                        <div v-if="sizeselect != size.id">
                                          {{size.size}}
                                          <small>  Qte {{size.quantite - size.vendu}} </small>
                                        </div>
                                      </li>
                                  </ul>
                                </div>
                            </div>
                            <div class="product_variant quantity">
                                <div style="width: 50%;">
                                  <p >Quantité (s) <b>{{Qte}}</b> </p>
                                 <a v-if="Qte > 1" class="btn btn btn-secondary col-3" style="color:#fff;"  @click="rmQuantite(Produit.stock)">
                                   <!-- <ion-icon name="remove"></ion-icon> -->-
                                 </a>
                                 &nbsp;&nbsp;
                                 <a class="btn btn-warning col-3" @click="addQuantite(Produit.stock)">
                                   <!-- <ion-icon ios="ios-add" md="md-add"></ion-icon> -->+
                                 </a>
                               </div>
                            </div>
                             <div class="action_links">
                                <ul>
                                    <li class="add_to_cart"><a @click="addtopanier(Produit,Qte)" title="add to cart"><i class="zmdi zmdi-shopping-cart-plus" ></i> Ajouter </a></li>
                                   <!-- <li class="wishlist"><a href="wishlist.html" title="Add to Wishlist"><i class="fa fa-heart-o" aria-hidden="true"></i></a></li> -->
                                    <!-- <li class="compare"><a href="#" title="compare"><i class="zmdi zmdi-swap"></i></a></li> -->
                                </ul>
                            </div>
                            <div class="product_meta">
                                <!-- <span>Catégorie: <a href="#"></a> {{Categorie.categorie}} </span> -->
                            </div>

                        </form>

                    </div>
                </div>
            </div>
        </div>
    </div>
    <!--product details end-->

    <!--product info start-->
    <div class="product_d_info">
        <div class="container">
            <div class="row">
                <div class="col-12">
                    <div class="product_d_inner">
                        <div class="product_info_button">
                            <ul class="nav" role="tablist">
                                <li >
                                    <a class="active" data-toggle="tab" href="#info" role="tab" aria-controls="info" aria-selected="false">Description</a>
                                </li>
                                <!-- <li>
                                     <a data-toggle="tab" href="#sheet" role="tab" aria-controls="sheet" aria-selected="false">Specification</a>
                                </li> -->
                                <li>
                                   <a data-toggle="tab" href="#reviews" role="tab" aria-controls="reviews" v-if="Questions.length > 0" aria-selected="false">Commentaires ({{ Questions.length }})</a>
                                   <a data-toggle="tab" href="#reviews" role="tab" aria-controls="reviews" v-if="Questions.length < 1" aria-selected="false">Commentaires</a>
                                </li>
                            </ul>
                        </div>
                        <div class="tab-content">
                            <div class="tab-pane fade show active" id="info" role="tabpanel" >
                                <div class="product_info_content">
                                    <p v-html="formatedDescription(Produit.description)"></p>
                                </div>
                            </div>
                            <div class="tab-pane fade" id="sheet" role="tabpanel" >
                                <div class="product_d_table">
                                   <form action="#">
                                        <table>
                                            <tbody>
                                                <!-- <tr>
                                                    <td class="first_child">Compositions</td>
                                                    <td>Polyester</td>
                                                </tr>
                                                <tr>
                                                    <td class="first_child">Styles</td>
                                                    <td>Girly</td>
                                                </tr>
                                                <tr>
                                                    <td class="first_child">Properties</td>
                                                    <td>Short Dress</td>
                                                </tr> -->
                                            </tbody>
                                        </table>
                                    </form>
                                </div>
                                <div class="product_info_content">
                                    <p></p>
                                </div>
                            </div>
                            <div class="tab-pane fade" id="reviews" role="tabpanel" >
                                <div class="reviews_wrapper">
                                    <h2 v-if="Questions.length > 0">Liste des Commentaires</h2>
                                    <div class="reviews_comment_box row">
                                        <div class="comment_thmb">
                                            <img src="assets/img/blog/comment2.jpg" alt="">
                                        </div>
                                        <div class="comment_text col-11 m-2" v-for="(question,index) in Questions" :key="index">
                                            <div class="reviews_meta">
                                                <!-- <div class="star_rating">
                                                    <ul>
                                                        <li><a href="#"><i class="ion-ios-star"></i></a></li>
                                                        <li><a href="#"><i class="ion-ios-star"></i></a></li>
                                                        <li><a href="#"><i class="ion-ios-star"></i></a></li>
                                                        <li><a href="#"><i class="ion-ios-star"></i></a></li>
                                                        <li><a href="#"><i class="ion-ios-star"></i></a></li>
                                                    </ul>   
                                                </div> -->
                                                <p><strong>Commentaire - {{ index+1 }} </strong></p>
                                                <span> {{ question.question }} </span>
                                            </div>
                                        </div>
                                    
                                    </div>
                                    <div class="comment_title">
                                        <h2>Faire une Commentaire</h2>
                                    </div>
                                    <div class="product_review_form">
                                        <!-- <form> -->
                                            <div class="row">
                                                <div class="col-lg-10 col-md-10 col-sm-12 col-xs-12">
                                                    <input placeholder="Commenter ici" v-model="question" type="text">
                                                </div> 
                                                <div class="col-lg-2 col-md-2 col-sm-12 col-xs-12" style="display: flex;flex-direction: column;justify-content: center;align-content: center;align-items: center;">
                                                    <button style="margin-top:2px; display:block; align-items:center;" @click="addquestion" >Envoyer</button>
                                                </div>  
                                            </div>
                                         <!-- </form>    -->
                                    </div> 
                                </div>     
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!--product info end-->

     <!--product similaire start-->
     <section class="product_area related_products mb-47">
        <div class="container">
            <div class="row new_product_three_container">
                <div class="col-12">
                    <div class="section_title">
                        <h2>Recommandation</h2>
                    </div>
                    <div class="product_column3 row">

                        <div class="single_product col-lg-3 col-md-4 col-6"  v-for="recommande in ProduitsSimilaires" :key="recommande.id">
                            <div class="product_thumb">
                                <div style="height: 261px; overflow: hidden;">
                                    <router-link :to="`/produit/${recommande.id}`" @click="redirectTo(`/produit/${recommande.id}`);event.preventDefault();">
                                        <img :src="urlImg+recommande.cover_img" alt="" />
                                    </router-link>
                                    
                                    </div>
                                <div v-if="recommande.rabais > 0" class="label_product">
                                    <span class="label_sale">Rabais {{recommande.rabais}} %</span>
                                </div>
                                <div class="quick_button">
                                    <a href="#" data-toggle="modal" data-target="#modal_box"  title="quick view"> <i class="zmdi zmdi-eye"></i></a>
                                </div>
                            </div>
                            <div class="product_content">
                                <div class="product_name" style="height: 35px; overflow: hidden;">
                                    <h3><router-link :to="`/produit/${recommande.id}`" @click="redirectTo(`/produit/${recommande.id}`);event.preventDefault();"> {{recommande.sounom}} </router-link></h3>
                                </div>
                                <div class="product_rating">
                                    <ul>
                                        <li><a href="#"><i class="zmdi zmdi-star-outline"></i></a></li>
                                        <li><a href="#"><i class="zmdi zmdi-star-outline"></i></a></li>
                                        <li><a href="#"><i class="zmdi zmdi-star-outline"></i></a></li>
                                        <li><a href="#"><i class="zmdi zmdi-star-outline"></i></a></li>
                                        <li><a href="#"><i class="zmdi zmdi-star-outline"></i></a></li>
                                    </ul>
                                </div>
                                <div v-if="recommande.rabais > 0 " class="price_box">
                                    <span class="current_price">{{recommande.prix-((recommande.prix * recommande.rabais)/100)}} HTG</span>
                                    <span class="old_price">{{recommande.prix}} HTG</span>
                                </div>

                                <div v-if="recommande.rabais == 0 " class="price_box">
                                    <span class="current_price">{{recommande.prix}} HTG</span>
                                </div>
                                <div class="action_links">
                                    <ul>
                                       <!-- <li class="wishlist col-2"><a href="wishlist.html" title="Add to Wishlist"><i class="fa fa-heart-o" aria-hidden="true"></i></a></li> -->
                                       <li class="add_to_cart"><a @click="addtopanier(recommande,recommande.stock)" title="add to cart"><i class="zmdi zmdi-shopping-cart-plus"></i> Ajouter </a></li>
                                        <!-- <li class="compare col-2"><a href="#" title="compare"><i class="zmdi zmdi-swap"></i></a></li> -->
                                    </ul>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    </section>
    <!--product similaire end-->

   
<Bannerhorizontal/>
<Piedpage/>
</template>

<script>
import router from '@/router'
import axios from 'axios'
import Entete from './Entete.vue'
import Bannerhorizontal from './Bannerhorizontal.vue'
import Piedpage from './Piedpage.vue'
import { useHead } from '@vueuse/head'

export default {
    name: 'Produit',data() {
        return {
            UserAuth: null,
            token: localStorage.token,
            Produit: null,
            Questions: null,
            urlImg: 'https://apiy.htvann.com/',
            apiUrl: 'https://apiy.htvann.com/api/',
            guest: false,
            user: null,
            Produit: [],
            img_cover: null,
            Article: [],
            Images: [],
            Couleurs: [],
            Sizes: [],
            Boutique: [],
            ProduitsSimilaires: [],
            Panier: [],
            btnModifierProd: false,
            Qte:1,
            sizeselect: null,
            couleurselect: null,
            stock: null,
            ProduitsNew: [],
            question:'',
        }
    },
    props: ['id'],
    created(){
        if(localStorage.UserAuth){
            this.UserAuth = JSON.parse(localStorage.UserAuth);
        }
        setTimeout(()=>{
            // console.log(this.id);
            axios.get('https://apiy.htvann.com/api/produit?produit_id='+this.id )
            .then((response)=>{
                this.Produit = response.data['0'];
                this.produitsimilaire();
                this.getquestions();
                // console.log(this.Produit);
                useHead({
                    title: this.Produit.sounom,
                    description: this.Produit.description,
                    meta: [
                        {
                            name: this.Produit.sounom,
                            content: this.Produit.description,
                        }
                    ],
                    
                });
            });
        }, 500);
        
    },
    components:{
        Entete,
        Bannerhorizontal,
        Piedpage,
    },
   methods:{
    redirectTo(path) {
        this.$router.push(path);
        setTimeout(function() {
          location.reload();
        }, 1);
    },
        addtopanier(produit,qte){

            if(this.UserAuth){
// alert('ok');
                let body = {
                    utilisateur_id:this.UserAuth.id,
                    produit_id:produit['id'],
                    couleur_id:this.couleurselect,
                    size_id:this.sizeselect,
                   quantite_ajouter: this.Qte,
                };
                axios.post(this.apiUrl+'panier/ajouter',body,{headers: {'content-Type': 'application/json','Authorization': 'Bearer '+this.token}})
                .then((response)=>{
                    if(response.status === 200){
                        router.push('/panier');
                    }
                });

            }else{
                produit['quantite_ajouter'] = qte;
                if(produit.rabais > 0){
                    let prixrab = produit.prix - ((produit.prix*produit.rabais)/100);
                    produit['prixrabais'] = prixrab;
                    produit['total'] = prixrab * qte;
                }else{
                    produit['total'] = produit.prix * qte;
                }
                this.Panier.push(produit);
                // console.log("Panier : =>>",this.Panier);
                // this.storage.set('Panier',this.Panier);
                localStorage.setItem('Panier',JSON.stringify(this.Panier));
                router.push('/panier');
            }

        },

        produitsimilaire(){

            axios.get('https://apiy.htvann.com/api/produits/similaire?similaire=departement&departement_id='+this.Produit.categorie.departement_id)
            .then((response)=>{
                this.ProduitsSimilaires = response.data.Produits;
                // console.log(this.ProduitsSimilaires);
            });

        },

        produitsnouveau(){
            this.apiservices.getsource((data)=>{
                this.ProduitsNew = data;
            },'produits');
        },

        // produitdetailweb(produit){
        //     this.navCtrl.push(ProduitdetailwebPage,{prod:produit});
        // },


        addsize(zise){
            this.sizeselect = zise;
        },

        addcouleur(couleur,pic){
            this.img_cover = pic;
            this.couleurselect = couleur;
        },

        addQuantite(stock){
            // console.log(stock);
            if(this.Qte < stock){
                this.Qte = this.Qte + 1;
            }
        },

        rmQuantite(stock){
            if(this.Qte > 0){
                this.Qte = this.Qte - 1;
            }
        },
        getquestions(){
            this.Questions = [];
            axios.get('https://apiy.htvann.com/api/produit/questions?produit_id='+this.id)
            .then((response)=>{
                this.Questions = response.data.Questions;
                console.log(this.Questions);
            });
        },
        addquestion(){
            console.log(this.question);
            let body = {
                    question:this.question,
                    produit_id:this.Produit['id'],
                    client_id:this.UserAuth.id,
                };
                axios.post(this.apiUrl+'produit/question',body,{headers: {'content-Type': 'application/json','Authorization': 'Bearer '+this.token}})
                .then((response)=>{
                    this.question = '';
                    this.getquestions();
                });
        },

        addreponse(questionId){
            let body = {
                    reponse:this.reponse,
                    question_id:questionId,
                    utilisateur_id:this.UserAuth.id,
                };
                axios.post(this.apiUrl+'produit/reponse',body,{headers: {'content-Type': 'application/json','Authorization': 'Bearer '+this.token}})
                .then((response)=>{
                    if(response.status === 200){
                        router.push('/panier');
                    }
                });
        },
        formatedDescription(valeur){
            return valeur.replace(/\n/g, "<br>");
        }

   },

}
</script>

<style>

</style>