<template>
    <!--header area start-->
    <header class="header_area header_color">
        <!--header top start-->
        <div class="header_top">
            <div class="container">  
                <div class="row align-items-center">
                    <div class="col-lg-4">
                        <div class="welcome_text">
                            <p>Bienvenue sur <span>HTVANN</span> <b v-if="UserAuth"> {{UserAuth.nom}}</b></p>
                        </div>
                    </div>
                    <div class="col-lg-8" v-if="UserAuth">
                        <div class="top_right text-right">
                            <ul>
                                <!-- <li class="currency"><a href="#"><i class="fa fa-dollar"></i> US Dollar <i class="zmdi zmdi-caret-down"></i></a>
                                    <ul class="dropdown_currency">
                                        <li><a href="#">EUR – Euro</a></li>
                                        <li><a href="#">GBP – British Pound</a></li>
                                        <li><a href="#">INR – India Rupee</a></li>
                                    </ul>
                                </li>
                                <li class="language"><a href="#"><i class="zmdi zmdi-dribbble"></i> English1 <i class="zmdi zmdi-caret-down"></i></a>
                                    <ul class="dropdown_language">
                                        <li><a href="#">English</a></li>
                                        <li><a href="#">Germany</a></li>
                                    </ul>
                                </li>  -->
                                <li class="top_links"><a href="#"><i class="zmdi zmdi-account"></i> Mon compte <i class="zmdi zmdi-caret-down"></i></a>
                                    <ul class="dropdown_links">
                                        <li><router-link to="/compte">Mon compte </router-link></li>
                                        <li><router-link to="/address">Mes Adresses </router-link></li>
                                        <li><router-link to="/commande">Mes commandes</router-link></li>
                                        <li><i name="logout"></i><a @click="logout()">Déconnecter</a></li>
                                    </ul>
                                </li>

                            </ul>
                        </div>
                    </div>

                    <div class="col-lg-8" v-if="UserAuth == null">
                        <div class="top_right text-right">
                            <ul>
                                <li class="top_links"><a href="#"><i class="zmdi zmdi-account"></i> Salut Visiteur <i class="zmdi zmdi-caret-down"></i></a>
                                    <ul class="dropdown_links">
                                        <li><a data-toggle="modal" data-target="#modal_box_signup" ><i class="zmdi zmdi-assignment-account"></i> | S'Inscrire </a></li>
                                        <li><a data-toggle="modal" data-target="#modal_box_login" ><i class="zmdi zmdi-sign-in"></i> | Se Connecter </a></li>
                                    </ul>
                                </li>

                            </ul>
                        </div>
                    </div>
                        
                </div>
            </div>
        </div>
        <!--header top start-->
        <!--header center area start-->
        <div class="header_middle">
            <div class="container">
                <div class="row align-items-center">
                    <div class="col-lg-3">
                        <div class="logo">
                            <a href="/"><img src="img/logo.png" alt=""></a>
                        </div>
                    </div>
                    <div class="col-lg-9">
                        <div class="header_middle_inner">
                            <div class="search-container col-8">
                                <form :action="`/chprd/${chache}`" @submit="getchache()">
                                <!-- <div class="hover_category">
                                        <select class="select_option" name="select" id="categori">
                                            <option selected value="1">Toutes Categories</option>
                                        </select>                        
                                </div> -->
                                    <div class="search_box col-12">
                                        <input placeholder="Chercher produit..." type="text" v-model="chache">
                                        <button type="button" @click="getchache()"><i class="zmdi zmdi-search"></i></button> 
                                        
                                    </div>
                                </form>
                            </div>
                            <div class="mini_cart_wrapper">
                                <a v-if="Panier.length == 0"><i class="zmdi zmdi-shopping-basket"></i> <span>0 Article</span> </a>
                                <a v-if="Panier.length > 0"><i class="zmdi zmdi-shopping-basket"></i> <span>{{ Panier.length }} Article - {{ PrixTotal }} HTG</span> </a>
                                <!--mini cart-->
                                <div class="mini_cart">
                                    <div class="row col-12">
                                        <div class="cart_button col-12" style="float:right;">
                                            <router-link to="/panier">Voir Panier</router-link>
                                            <!-- <a href="checkout.html">Checkout</a> -->
                                        </div>
                                    </div>
                                    <br/>
                                    <div v-for="produi in Panier" class="cart_item">
                                        <div class="cart_img">
                                            <a href="#"><img :src="urlImg+produi.cover_img" alt=""></a>
                                        </div>
                                        <div class="cart_info">
                                            <a href="#">{{produi.sounom}}</a>

                                            <span class="quantity">Qte: {{ produi.quantite_ajouter }}</span>
                                            <span class="price_cart">Prix: {{ produi.prix }} HTG</span>
                                            <span class="price_cart">Rabais: {{ produi.rabais }}%</span>

                                        </div>
                                        <div class="cart_remove">
                                            <!-- <a href="#"><i class="ion-android-close"></i></a> -->
                                        </div>
                                    </div>
                                    <div class="mini_cart_table">
                                        <div class="cart_total">
                                            <span>Total:</span>
                                            <span class="price">{{ PrixTotal }} HTG</span>
                                        </div>
                                    </div>

                                    <div class="mini_cart_footer">
                                        <div class="cart_button">
                                            <router-link to="/panier">Voir Panier</router-link>
                                            <!-- <a href="checkout.html">Checkout</a> -->
                                        </div>
                                    </div>

                                </div>
                                <!--mini cart end-->
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!--header center area end-->
        
        <!--header middel start-->
        <div class="header_bottom">
            <div class="container">
                <div class="row align-items-center">
                    <div class="col-lg-3 main_menu">
                        <div class="categories_menu categori_three">
                            <div class="categories_title">
                                <h2 class="categori_toggle">Départements</h2>
                            </div>
                            <div class="categories_menu_toggle">
                                <ul> 
                                    <li><a href="#"><span><i class="zmdi zmdi-tv"></i></span> TV & Audio</a></li>
                                    <li><a href="#"><span><i class="zmdi zmdi-tv-list"></i></span> home funiture</a></li>
                                    <li><a href="#"><span><i class="zmdi zmdi-cake"></i></span> toys for kids</a></li>
                                    <li><a href="#"><span><i class="zmdi zmdi-memory"></i></span> Memory Cards</a></li>
                                    <li><a href="#"><span><i class="zmdi zmdi-case-download"></i></span> Living Room</a></li>
                                    <li class="hidden"><a href="shop-left-sidebar.html">New Sofas</a></li>
                                    <li class="hidden"><a href="shop-left-sidebar.html">Sleight Sofas</a></li>
                                    <li><a href="#" id="more-btn"><i class="fa fa-plus" aria-hidden="true"></i> More Categories</a></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-9">
                        <div class="main_menu menu_three header_position"> 
                            <nav>  
                                <ul>

                                    <li ><router-link to="/"><i class="zmdi zmdi-home"></i> home </router-link>
                                        
                                    </li>
                                    <li v-if="UserAuth != null" class="mega_items"><router-link to="/maboutique"><i class="zmdi zmdi-shop"></i>Ma Boutique </router-link> </li>
                                    <li><router-link to="/about"><i class="zmdi zmdi-comments"></i> A PROPOS DE NOUS</router-link></li>
                                    <li><router-link to="/contact"><i class="zmdi zmdi-account-box-mail"></i>  Contactez-NOUS</router-link></li>
                                </ul>  
                            </nav> 
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!--header middel end-->
    </header>
    <!--header area end-->
    <!--Offcanvas menu area start-->

    <div v-if="showingmenumobil === 'active'" class="off_canvars_overlay active">
            
    </div>
    <div class="Offcanvas_menu">
        <div class="container">
            <div class="row">
                <div class="col-12">
                    <div class="canvas_open" @click="showmobilmenu()">
                        <span>MENU</span>
                        <a href="javascript:void(0)"><i class="ion-navicon"></i></a>
                    </div><br/>
                    <form :action="`/chprd/${chache}`" @submit="getchache()">
                        <div class="search_box canvas_open col-12">
                            <input placeholder="Chercher produit..." type="text" v-model="chache">
                            <button type="button" @click="getchache()"><i class="zmdi zmdi-search"></i></button> 
                        </div>
                    </form>
                    <div  v-if="showingmenumobil === 'active'" class="Offcanvas_menu_wrapper active">
                        <div class="canvas_close" @click="hidshowmobilmenu()">
                                <a href="javascript:void(0)"><i class="ion-android-close"></i></a>  
                        </div>
                        <div class="welcome_text">
                            <p>Bienvenue sur <span>HTVANN</span> <b v-if="UserAuth"> {{UserAuth.nom}}</b></p>
                        </div>
                        
                        <div class="top_right">
                            <ul v-if="UserAuth ==null">
                                <li class="top_links"><a href="#"><i class="zmdi zmdi-account"></i> Salut Visiteur <i class="zmdi zmdi-caret-down"></i></a>
                                    <ul class="dropdown_links">
                                        <li><a data-toggle="modal" data-target="#modal_box_signup" ><i class="zmdi zmdi-assignment-account"></i> | S'Inscrire </a></li>
                                        <li><a data-toggle="modal" data-target="#modal_box_login" ><i class="zmdi zmdi-sign-in"></i> | Se Connecter </a></li>
                                    </ul>
                                </li>
                            </ul>
                            <ul v-if="UserAuth">
                                <li class="top_links"><a href="#"><i class="zmdi zmdi-account"></i> Mon Compte <i class="zmdi zmdi-caret-down"></i></a>
                                    <ul class="dropdown_links">
                                        <li><router-link to="/compte">Mon compte </router-link></li>
                                        <!-- <li><router-link to="/address">Mes Adresses </router-link></li> -->
                                        <li><router-link to="/commande">Mes commandes</router-link></li>
                                        <li><i name="logout"></i><a @click="logout()">Déconnecter</a></li>
                                    </ul>
                                </li>
                            </ul>
                        </div>
                        <div class="mini_cart_wrapper">
                            <router-link to="/panier" v-if="Panier.length == 0"><i class="zmdi zmdi-shopping-basket"></i> <span>0 Article</span> </router-link>
                            <router-link to="/panier" v-if="Panier.length > 0"><i class="zmdi zmdi-shopping-basket"></i> <span>{{ Panier.length }} Article - {{ PrixTotal }} HTG</span> </router-link>            
                            <!--mini cart-->
                                <div class="mini_cart">
                                <div class="cart_item">
                                    <div class="cart_img">
                                        <a href="#"><img src="assets/img/s-product/product.jpg" alt=""></a>
                                    </div>
                                    <div class="cart_info">
                                        <a href="#">Condimentum Watches</a>

                                        <span class="quantity">Qty: 1</span>
                                        <span class="price_cart">$60.00</span>

                                    </div>
                                    <div class="cart_remove">
                                        <a href="#"><i class="ion-android-close"></i></a>
                                    </div>
                                </div>
                                <div class="cart_item">
                                    <div class="cart_img">
                                        <a href="#"><img src="assets/img/s-product/product2.jpg" alt=""></a>
                                    </div>
                                    <div class="cart_info">
                                        <a href="#">Officiis debitis</a>
                                        <span class="quantity">Qty: 1</span>
                                        <span class="price_cart">$69.00</span>
                                    </div>
                                    <div class="cart_remove">
                                        <a href="#"><i class="ion-android-close"></i></a>
                                    </div>
                                </div>
                                <div class="mini_cart_table">
                                    <div class="cart_total">
                                        <span>Subtotal:</span>
                                        <span class="price">$138.00</span>
                                    </div>
                                </div>

                                <div class="mini_cart_footer">
                                    <div class="cart_button">
                                        <a href="cart.html">View cart</a>
                                        <a href="checkout.html">Checkout</a>
                                    </div>
                                </div>

                            </div>
                            <!--mini cart end-->
                        </div>
                        <div id="menu" class="text-left ">
                                <ul class="offcanvas_main_menu">
                                <li class="menu-item-has-children active">
                                    <a href="#">Menu</a>
                                    <ul class="sub-menu">
                                        <li ><router-link to="/"><i class="zmdi zmdi-home"></i> home </router-link></li>
                                        <li class="mega_items"><router-link to="/maboutique"><i class="zmdi zmdi-shop"></i>Ma Boutique </router-link> </li>
                                        <li><router-link to="/about"><i class="zmdi zmdi-comments"></i> A PROPOS DE NOUS</router-link></li>
                                        <li><router-link to="/contact"><i class="zmdi zmdi-account-box-mail"></i>  Contactez-NOUS</router-link></li>
                                    
                                    </ul>
                                </li>
                            </ul>
                        </div>

                        <div class="Offcanvas_footer">
                            <span><a href="#"><i class="fa fa-envelope-o"></i> info@htvann.com</a></span>
                            <ul>
                                <li class="facebook"><a href="#"><i class="fa fa-facebook"></i></a></li>
                                <li class="twitter"><a href="#"><i class="fa fa-twitter"></i></a></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!--Offcanvas menu area end-->
    <Login/>
    <Register/>
     <!--shop  area start-->
    <div class="home_product_area home_product_three mb-50">
        <div class="container">
            <div class="row">
                <div class="col-12">
                    <!--shop wrapper start-->
                    <!--shop toolbar start-->
                    <!-- <div class="shop_title">
                        <h1>shop</h1>
                    </div>
                    <div class="shop_toolbar_wrapper">
                        <div class="shop_toolbar_btn">

                            <button data-role="grid_3" type="button" class=" btn-grid-3" data-toggle="tooltip" title="3"></button>

                            <button data-role="grid_4" type="button"  class="active btn-grid-4" data-toggle="tooltip" title="4"></button>

                            <button data-role="grid_list" type="button"  class="btn-list" data-toggle="tooltip" title="List"></button>
                        </div>
                        <div class=" niceselect_option">

                            <form class="select_option" action="#">
                                <select name="orderby" id="short">

                                    <option selected value="1">Sort by average rating</option>
                                    <option  value="2">Sort by popularity</option>
                                    <option value="3">Sort by newness</option>
                                    <option value="4">Sort by price: low to high</option>
                                    <option value="5">Sort by price: high to low</option>
                                    <option value="6">Product Name: Z</option>
                                </select>
                            </form>


                        </div>
                        <div class="page_amount">
                            <p>Showing 1–9 of 21 results</p>
                        </div>
                    </div> -->
                    <!--shop toolbar end-->
                   
                    <div class="shop_title">
                        <h3>Resultat</h3>
                        <hr>
                    </div>
                    <div class="row no-gutters ">
                        <div v-if="produits.length < 1" class="col-12 text-center">
                            <br><br>
                            <h2> Aucun Resultat</h2>
                        </div>
                        <div class="row product_column4 owl ">
                            <div v-for="produit in produits" :key="produit.id" class="single_product col-lg-3 col-md-4 col-6">
                                <div class="product_thumb">
                                    <div style="height: 250px; overflow: hidden;"><img :src="`https://apiy.htvann.com/${produit.cover_img}`" alt=""></div>

                                    <div v-if="produit.rabais > 0" class="label_product">
                                        <span class="label_sale">Rabais {{produit.rabais}} %</span>
                                    </div>

                                    <!-- <div class="quick_button">
                                        <a href=""  title="quick view"> <i class="zmdi zmdi-eye"></i></a>
                                    </div> -->
                                </div>
                                <div class="product_content">
                                    <div class="product_name" style="height: 35px; overflow: hidden;">
                                        <!-- <h3 ><router-link :to="{name: 'produit', query: { idp: 'tets'}}"> {{produitd.sounom}} </router-link></h3> -->
                                        <h3 ><router-link :to="`/produit/${produit.id}`"> {{produit.sounom}} </router-link></h3>
                                    </div>
                                    <div class="product_rating">
                                        <ul>
                                            <li><a href="#"><i class="zmdi zmdi-star-outline"></i></a></li>
                                            <li><a href="#"><i class="zmdi zmdi-star-outline"></i></a></li>
                                            <li><a href="#"><i class="zmdi zmdi-star-outline"></i></a></li>
                                            <li><a href="#"><i class="zmdi zmdi-star-outline"></i></a></li>
                                            <li><a href="#"><i class="zmdi zmdi-star-outline"></i></a></li>
                                        </ul>
                                    </div>

                                    <div v-if="produit.rabais > 0 " class="price_box">
                                        <span class="current_price">{{produit.prix-((produit.prix * produit.rabais)/100)}} HTG</span>
                                        <span class="old_price">{{produit.prix}} HTG</span>
                                    </div>

                                    <div v-if="produit.rabais == 0 " class="price_box">
                                        <span class="current_price">{{produit.prix}} HTG</span>
                                    </div>

                                    <div class="action_links">
                                        <ul>
                                            <!-- <li class="wishlist"><a href="wishlist.html" title="Add to Wishlist"><i class="fa fa-heart-o" aria-hidden="true"></i></a></li> -->
                                            <li class="add_to_cart">
                                                <router-link :to="`/produit/${produit.id}`"><i class="zmdi zmdi-shopping-cart-plus"></i> Ajouter </router-link>
                                            </li>
                                            <!-- <li class="compare"><a href="#" title="compare"><i class="zmdi zmdi-swap"></i></a></li> -->
                                        </ul>
                                    </div>
                                </div>
                            </div>

                        </div>
                        
                        
                    </div>

                    <!-- <div class="shop_toolbar t_bottom">
                        <div class="pagination">
                            <ul>
                                <li class="current">1</li>
                                <li><a href="#">2</a></li>
                                <li><a href="#">3</a></li>
                                <li class="next"><a href="#">next</a></li>
                                <li><a href="#">>></a></li>
                            </ul>
                        </div>
                    </div> -->
                    <!--shop toolbar end-->
                    <!--shop wrapper end-->
                </div>
            </div>
        </div>
    </div>
    <!--shop  area end-->

     <!-- modal area start-->
     <div class="modal fade" id="modal_box" tabindex="-1" role="dialog"  aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered" role="document">
            <div class="modal-content">
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                  <span aria-hidden="true">&times;</span>
                </button>
                <div class="modal_body">
                    <div class="container">
                        <div class="row">
                            <div class="col-lg-5 col-md-5 col-sm-12">
                                <div class="modal_tab">  
                                    <div class="tab-content product-details-large">
                                        <div class="tab-pane fade show active" id="tab1" role="tabpanel" >
                                            <div class="modal_tab_img">
                                                <a href="#"><img src="assets/img/product/product37.jpg" alt=""></a>    
                                            </div>
                                        </div>
                                        <div class="tab-pane fade" id="tab2" role="tabpanel">
                                            <div class="modal_tab_img">
                                                <a href="#"><img src="assets/img/product/product24.jpg" alt=""></a>    
                                            </div>
                                        </div>
                                        <div class="tab-pane fade" id="tab3" role="tabpanel">
                                            <div class="modal_tab_img">
                                                <a href="#"><img src="assets/img/product/product25.jpg" alt=""></a>    
                                            </div>
                                        </div>
                                        <div class="tab-pane fade" id="tab4" role="tabpanel">
                                            <div class="modal_tab_img">
                                                <a href="#"><img src="assets/img/product/product22.jpg" alt=""></a>    
                                            </div>
                                        </div>
                                    </div>
                                    <div class="modal_tab_button">    
                                        <ul class="nav product_navactive owl-carousel" role="tablist">
                                            <li >
                                                <a class="nav-link active" data-toggle="tab" href="#tab1" role="tab" aria-controls="tab1" aria-selected="false"><img src="assets/img/product/productbig1.jpg" alt=""></a>
                                            </li>
                                            <li>
                                                 <a class="nav-link" data-toggle="tab" href="#tab2" role="tab" aria-controls="tab2" aria-selected="false"><img src="assets/img/product/productbig2.jpg" alt=""></a>
                                            </li>
                                            <li>
                                               <a class="nav-link button_three" data-toggle="tab" href="#tab3" role="tab" aria-controls="tab3" aria-selected="false"><img src="assets/img/product/productbig4.jpg" alt=""></a>
                                            </li>
                                            <li>
                                               <a class="nav-link" data-toggle="tab" href="#tab4" role="tab" aria-controls="tab4" aria-selected="false"><img src="assets/img/product/productbig5.jpg" alt=""></a>
                                            </li>

                                        </ul>
                                    </div>    
                                </div>  
                            </div> 
                            <div class="col-lg-7 col-md-7 col-sm-12">
                                <div class="modal_right">
                                    <div class="modal_title mb-10">
                                        <h2>Handbag feugiat</h2> 
                                    </div>
                                    <div class="modal_price mb-10">
                                        <span class="new_price">$64.99</span>    
                                        <span class="old_price" >$78.99</span>    
                                    </div>
                                    <div class="modal_description mb-15">
                                        <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Mollitia iste laborum ad impedit pariatur esse optio tempora sint ullam autem deleniti nam in quos qui nemo ipsum numquam, reiciendis maiores quidem aperiam, rerum vel recusandae </p>    
                                    </div> 
                                    <div class="variants_selects">
                                        <div class="variants_size">
                                           <h2>size</h2>
                                           <select class="select_option">
                                               <option selected value="1">s</option>
                                               <option value="1">m</option>
                                               <option value="1">l</option>
                                               <option value="1">xl</option>
                                               <option value="1">xxl</option>
                                           </select>
                                        </div>
                                        <div class="variants_color">
                                           <h2>color</h2>
                                           <select class="select_option">
                                               <option selected value="1">purple</option>
                                               <option value="1">violet</option>
                                               <option value="1">black</option>
                                               <option value="1">pink</option>
                                               <option value="1">orange</option>
                                           </select>
                                        </div>
                                        <div class="modal_add_to_cart">
                                            <form action="#">
                                                <input min="0" max="100" step="2" value="1" type="number">
                                                <button type="submit">add to cart</button>
                                            </form>
                                        </div>   
                                    </div>
                                    <div class="modal_social">
                                        <h2>Share this product</h2>
                                        <ul>
                                            <li class="facebook"><a href="#"><i class="fa fa-facebook"></i></a></li>
                                            <li class="twitter"><a href="#"><i class="fa fa-twitter"></i></a></li>
                                            <li class="pinterest"><a href="#"><i class="fa fa-pinterest"></i></a></li>
                                            <li class="google-plus"><a href="#"><i class="fa fa-google-plus"></i></a></li>
                                            <li class="linkedin"><a href="#"><i class="fa fa-linkedin"></i></a></li>
                                        </ul>    
                                    </div>      
                                </div>    
                            </div>    
                        </div>     
                    </div>
                </div>    
            </div>
        </div>
    </div>

     <!-- modal area end-->

  <BannerhorizontalVue/>
  <PiedpageVue/>
</template>

<script>
import axios from 'axios'
import Login from '@/views/Login.vue'
import Register from '@/views/Register.vue'
import BannerhorizontalVue from './Bannerhorizontal.vue'
import EnteteVue from './Entete.vue'
import PiedpageVue from './Piedpage.vue'

export default {
    components:{
        EnteteVue,
        BannerhorizontalVue,
        PiedpageVue,
        Login,
        Register,

    },data() {
        return {
            produits: [],
            urlImg: 'https://apiy.htvann.com/',
            apiUrl: 'https://apiy.htvann.com/api/',
            token: null,
            Panier: [],
            PrixTotal: 0,
            UserAuth: null,
            chache: '',
            showingmenumobil: '',
        }
    },
    props: ['artcl'],
    created(){
        if (localStorage.UserAuth) {
            this.UserAuth = JSON.parse(localStorage.UserAuth);
            this.token = localStorage.token;
        }
        axios.get(this.apiUrl+'produits/search?produit='+this.artcl).then((response)=>{
            // console.log(response.data);
            this.produits = response.data;
        });
    },mounted() {
        // console.log(this.$router.params.artcl);
        // axios.get(this.apiUrl+'produits/search?produit='+this.artcl).then((response)=>{
        //     console.log(response.data);
        //     this.produits = response.data;
        // });
    },
    methods: {
        getchache(){
            this.produits = [];
            axios.get(this.apiUrl+'produits/search?produit='+this.chache).then((response)=>{
                // console.log(response.data);
                this.produits = response.data;
            });
        },
        logout(){
            this.UserAuth = null;
            localStorage.clear();
            // window.location.reload();
            this.$router.push({path: '/'});
        },
        showmobilmenu(){
            this.showingmenumobil = 'active';
        },
        hidshowmobilmenu(){
            this.showingmenumobil = '';
        }
    },
}
</script>

<style>

</style>