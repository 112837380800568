<template>
    <Entete/>
   <!--shopping cart area start -->
   <div class="shopping_cart_area mt-60">
        <div class="container">
            <form action="#">
                <div class="row">
                    <div class="col-12">
                        <div class="table_desc">
                            <div class="cart_page table-responsive">
                                <table>
                            <thead>
                                <tr>
                                    <th class="product_thumb">Image</th>
                                    <th class="product_name">Produit</th>
                                    <th class="product-price">Prix</th>
                                    <th class="product-price">Rabais</th>
                                    <!-- <th class="product-price">Prix - Rabais</th> -->
                                    <th class="product_quantity">Quantité</th>
                                    <th class="product_total">Total</th>
                                    <th class="product_option">Commander</th>
                                    <th class="product_remove">Retirer</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="(produit, index) in Panier" :key="index">
                                    <td class="product_thumb"><a href="#"><img :src="urlImg+produit.cover_img" alt=""></a></td>
                                    <td style="text-align: start;">
                                      <a href="#"> {{produit.sounom}} </a>
                                      <h3 v-if="produit.size_id">Size : {{produit.size}} </h3>
                                      <div v-if="produit.couleur_id" class="col-12">
                                        Couleur:
                                        <div class="col-3">
                                          <img src="{{urlImg}}{{produit.cover_img}}" alt="zo-th-1"/>
                                        </div>
                                      </div>
                                    </td>
                                    <td class="product-price">{{produit.prix}} HTG</td>
                                    <td class="product-price">{{produit.rabais}} %</td>
                                    <!-- <td class="product-price">{{produit.prixrabais}} HTG</td> -->
                                    <td class="product_quantity">
                                      Quantité {{produit.quantite_ajouter}}
                                      <div class="product_variant quantity">

                                         <a v-if="produit.quantite_ajouter > 1" class="btn btn btn-secondary col-4" style="color:#fff;" @click="rmquantite(produit,index)" >
                                           -
                                         </a>
                                         &nbsp;&nbsp;
                                         <a  v-if="produit.stock - produit.vente > 1" class="btn btn-warning col-4" @click="addquantite(produit,index)" >
                                           +
                                         </a>

                                      </div>
                                    </td>
                                    <td class="product_total">{{produit.total}} HTG</td>
                                    <td class="product_option"><a @click="caissesingle(produit.id)">Commander</a></td>
                                    <td class="product_remove"><a @click="retirer(produit)"><i class="fa fa-trash-o"></i></a></td>
                                </tr>
                            </tbody>
                        </table>
                            </div>
                            <!-- <div class="cart_submit">
                                <button type="submit">Enregistrer</button>
                            </div> -->
                        </div>
                     </div>
                 </div>
                 <!--coupon code area start-->
                <div class="coupon_area">
                    <div class="row">
                        <!-- <div class="col-lg-6 col-md-6">
                            <div class="coupon_code left">
                                <h3>Coupon</h3>
                                <div class="coupon_inner">
                                    <p>Enter your coupon code if you have one.</p>
                                    <input placeholder="Coupon code" type="text">
                                    <button type="submit">Apply coupon</button>
                                </div>
                            </div>
                        </div> -->
                        <div class="col-lg-6 col-md-6 offset-lg-6 offset-md-6" >
                            <div class="coupon_code right">
                                <h3>Panier Total</h3>
                                <div class="coupon_inner">

                                   <div class="cart_subtotal">
                                       <p>Total</p>
                                       <p class="cart_amount"> {{PrixTotal}} HTG</p>
                                   </div>
                                   <!-- <button *ngIf="PrixTotal > 0" (click)="goTocaisse()" ion-button round small outline float-right>Commander</button> -->
                                   <div v-if="PrixTotal > 0" class="checkout_btn top_six">
                                       <router-link v-if="UserAuth != null" to="/commander" style="text-decoration: none;">Commander</router-link>
                                       <a href="#" v-if="UserAuth == null" data-toggle="modal" data-target="#modal_box_login">Commander</a>
                                   </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <!--coupon code area end-->
            </form>
        </div>
    </div>
     <!--shopping cart area end -->

    <Piedpage/>
</template>

<script>
import axios from 'axios'
import Entete from './Entete.vue'
import Piedpage from '@/views/Piedpage.vue'

export default {
    name: 'Panier',
    components:{
        Entete,
        Piedpage,
    },
    data() {
        return {
            urlImg: 'https://apiy.htvann.com/',
            apiUrl: 'https://apiy.htvann.com/api/',
            UserAuth: null,
            token: null,
            msg: null,
            user: null,
            guest: null,
            Panier: [],
            PrixTotal: 0,
            Qte: 1,
            stock: null,
        }
    },
    created() {
        if (localStorage.UserAuth) {
            this.UserAuth = JSON.parse(localStorage.UserAuth);
            this.token = localStorage.token;
        }
        
    },mounted() {
        if(localStorage.UserAuth){
            this.getPanier();
        }else{
            this.getPanierGuest();
        }
        
    },methods: {
        getPanier(){

            axios.get(this.apiUrl+'panier?user_id='+this.UserAuth.id,{headers: {'content-Type': 'application/json','Authorization': 'Bearer '+this.token}})
            .then((response)=>{
                this.Panier = [];
                this.PrixTotal = 0;
                response.data.Panier.forEach(element => {
                this.Panier.push(element);
                this.PrixTotal = this.PrixTotal + element.total;
                });
                // console.log(this.Panier);
                localStorage.setItem('Panier',JSON.stringify(this.Panier));
            
            });

        },
        getPanierGuest(){
            
            this.Panier = [];
            this.PrixTotal = 0;
            // console.log(JSON.parse(localStorage.Panier));
            if(localStorage.Panier){
                JSON.parse(localStorage.Panier).forEach(element => {
                    this.Panier.push(element);
                    this.PrixTotal = this.PrixTotal + element.total;
                });
            }
            
            // console.log(this.Panier);

        },
        retirer(Produit){

            // this.loading();
            if(this.UserAuth){

                axios.get(this.apiUrl+'panier/retirer?panier_id='+Produit.panier_id,{headers: {'content-Type': 'application/json','Authorization': 'Bearer '+this.token}})
                .then((response)=>{

                if(response.status){
                    this.getPanier();
                }else{
                    this.msg = "Une erreur s'est produite";
                }
                });
                this.getPanier();

            }else{
                // console.log('ok');
                let panier2 = this.Panier;
                this.Panier = [];
                this.PrixTotal = 0;
                panier2.forEach(element => {
                    if(element.id == Produit.id){

                    }else{
                    this.Panier.push(element);
                    let prix=0;
                    prix = (element.prix-(element.prix*(element.rabais/100)))*element.Qte;
                    this.PrixTotal = this.PrixTotal + prix;
                    }
                });

                localStorage.setItem('Panier',this.Panier);
            }
        },
        addquantite(produit,key){
            // console.log(key);
            let vstock = produit.stock - produit.vente;
            if(vstock > 0){
                if(produit.quantite_ajouter < produit.stock){
                this.Panier[key].quantite_ajouter = this.Panier[key].quantite_ajouter  + 1;
                let body = {
                    panier_id:produit.panier_id,
                    quantite_ajouter:this.Panier[key].quantite_ajouter,
                };
                    // console.log(body);
                    axios.post(this.apiUrl+'panier/modifier/quantite',body,{headers: {'content-Type': 'application/json','Authorization': 'Bearer '+this.token}}).then((response)=>{
                        // console.log(data);
                        if(response.status == true){

                        this.getPanier();

                        }else{
                        // this.msg = "Problème de connexion";
                        }

                    });
                }
            }

        },

        rmquantite(produit,key){

            if(produit.quantite_ajouter > 1){
                this.Panier[key].quantite_ajouter = this.Panier[key].quantite_ajouter  - 1;

                let body = {
                    panier_id:produit.panier_id,
                    quantite_ajouter:this.Panier[key].quantite_ajouter,
                };
                // console.log(body);
                axios.post(this.apiUrl+'panier/modifier/quantite',body,{headers: {'content-Type': 'application/json','Authorization': 'Bearer '+this.token}}).then((response)=>{

                // console.log(data);
                if(response.status == true){

                    this.getPanier();

                }else{
                    // this.msg = "Problème de connexion";
                }

                });

            }

        }

    }
}
</script>

<style>

</style>