<template>
  <entete/>

  <div class="error_section">
        <div class="container">
            <div class="row">
                <div class="col-12">
                    <div class="error_form">
                        <h1 style="color:darkorange">404</h1>
                        <h2>Opps! Ressource indisponible</h2>
                        <p>
                            Désolé, la page que vous recherchez n'existe pas, ou est temporairement indisponible.
                        </p>
                        <form :action="`/chprd/${chache}`" @submit="getchache()">
                            <input placeholder="Chercher produit..." type="text" v-model="chache">
                            <button type="submit"><i class="ion-ios-search-strong"></i></button>
                        </form>
                        <!-- <a href="index.html">Back to home page</a> -->
                        <router-link to="/"><i></i> Retour vers Accueil </router-link>
                    </div>
                </div>
            </div>
        </div>
    </div>

  <bannerhorizontal/>
  <piedpage/>
</template>

<script>
import Bannerhorizontal from './Bannerhorizontal.vue'
import Entete from './Entete.vue'
import Piedpage from './Piedpage.vue'
export default {
  components: { Entete, Bannerhorizontal, Piedpage },
  data() {
    return {
        chache: ''
    }
  },

}
</script>

<style>

</style>