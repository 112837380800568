<template>
    <footer class="footer_widgets footer_three">
        <div class="container">  
            <div class="footer_top">
                <div class="row">
                    <div class="col-lg-4 col-md-5">
                        <div class="widgets_container contact_us">
                            <a href="/" style="width:160px;"><img src="img/logo.png" alt=""></a>
                            <div class="footer_contact">
                                <ul>
                                    <li><i class="zmdi zmdi-home"></i><span>Adresse:</span>177, Faubert, Pétion Ville Ouest, Haïti</li>
                                    <li><i class="zmdi zmdi-phone-setting"></i><span>Téléphone:</span><a href="tel:(+509) 44463145">(+509) 44 46 31 45</a> / <a href="tel:(+509) 41837116"> 41 83 71 16</a> </li>
                                    <li><i class="zmdi zmdi-email"></i><span>E-mail:</span>  <a href="mail:info@htvann.com">info@htvann.com</a></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-5 col-md-7">
                        <div class="row">
                            <div class="col-lg-6 col-md-6">
                                <div class="widgets_container widget_menu">
                                    <h3>NOS SERVICES</h3>
                                    <div class="footer_menu">
                                        <ul>
                                            <!-- <li><a >Livraison & Retour</a></li>
                                            <li><a > Achats Sécurisé</a></li>
                                            <li><a > Affiliations</a></li> -->
                                            <li><a href="/about" > Contacter Nous</a></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-6 col-md-6">
                                <div class="widgets_container widget_menu">
                                    <h3>Information</h3>
                                    <div class="footer_menu">
                                        <ul>
                                            <li><a href="/about">A Propos </a></li>
                                            <!-- <li><a href="#">Livraison Info</a></li> -->
                                            <!-- <li><a href="#">Theme & Conditions</a></li> -->
                                            <!-- <li><a href="#">QFP</a></li> -->

                                        </ul>
                                    </div>

                                </div>
                            </div>
                        </div>
                        
                    </div>
                    <div class="col-lg-3 col-md-5">
                        <div class="widgets_container">
                            <h3>Média Sociaux</h3>
                            <div class="instagram_gallery">

                                <div class="social_icone">
                                    <ul>
                                        <li class="" ><a href="https://api.whatsapp.com/send?phone=50944463145" title="50944463145" target="_blank" style="background-color: green;"><i class="fa fa-whatsapp"></i></a>
                                            <div class="social_title">
                                                <p><small>Contactez-nous</small></p>
                                                <h3>Whatsapp</h3>
                                            </div>
                                        </li>
                                        <li class="facebook"><a href="https://www.facebook.com/Htvann-104099054904762" target="_blank" title=""><i class="fa fa-facebook"></i></a>
                                            <div class="social_title">
                                            <p><small>Trouvez-nous</small></p>
                                            <h3>Facebook</h3>
                                            </div>
                                        </li>
                                    </ul>
                                </div>

                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
        
         <div class="footer_bottom">      
            <div class="container">
               <div class="row">
                    <div class="col-lg-6 col-md-7">
                        <div class="copyright_area">
                            <p>Copyright &copy; 2023 <a href="#"> HTvann </a>  All Right Reserved.</p>
                        </div>
                    </div>
                    <div class="col-lg-6 col-md-5">
                        <div class="footer_payment text-right">
                            <p><img src="assets/img/icon/payment.png" alt=""></p>
                        </div>
                    </div>
                </div>
            </div>
        </div>     
    </footer>
</template>

<script>
export default {
    name:'Piedpage',
};
</script>

<style>

</style>