<template>
    <Entete/>
    <!-- my account start  -->
    <section class="main_content_area">
        <div class="container">   
            <div class="account_dashboard product_column3">
                <div class="row">
                    <div class="col-lg-6 offset-lg-3 col-md-6 offset-md-3" >
                        <h4>Ajouter une adresse de livraison</h4>
                        <br/>
                        <div class="form-group">
                            <label>Nom</label>
                            <input type="text" v-model="nom" v-on:blur="verifynom()" class="form-control"/>
                            <p class="text-danger"> {{ nommsg }} </p>
                        </div>
                        <div class="form-group">
                            <label>Téléphone</label>
                            <input type="number"  v-model="phone" v-on:blur="verifyphone()" class="form-control"/>
                            <p class="text-danger"> {{ phonemsg }} </p>
                        </div>
                        <div class="form-group">
                            <label># Numéro et Rue</label>
                            <input type="text" v-model="rue" v-on:blur="verifyrue()" class="form-control"/>
                            <p class="text-danger"> {{ ruemsg }} </p>
                        </div>
                        <div class="form-group">
                            <label>Zone</label>
                            <input type="text" v-model="zone" v-on:blur="verifyzone()" class="form-control"/>
                            <p class="text-danger"> {{ zonemsg }} </p>
                        </div>
                        <div class="form-group">
                            <label>Commune</label>
                            <select v-model="commune" v-on:blur="verifycommune()" class="form-control">
                                <option>Choisir Commune</option>
                                <option v-for="commune in communes" v-bind:value="commune.id">{{ commune.commune }}</option>
                            </select>
                            <p class="text-danger"> {{ communemsg }} </p>
                        </div>
                        <div class="form-group">
                            <label>Departement</label>
                            <select v-model="departement" v-on:blur="verifydepartement()" class="form-control">
                                <option>Choisir Departement</option>
                                <option v-for="departement in departements" v-bind:value="departement.id">{{ departement.geodepartement }}</option>
                            </select>
                            <p class="text-danger"> {{ departementmsg }} </p>
                        </div>
                        <div class="form-group">
                            <button @click="saveadresse()" class="btn btn-info float-right">Enregistrer</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <BannerhorizontalVue/>
    <PiedpageVue/>
</template>

<script>
import axios from 'axios';
import Entete from './Entete.vue';
import BannerhorizontalVue from './Bannerhorizontal.vue';
import PiedpageVue from './Piedpage.vue';

export default {
    components: {
        Entete,
        PiedpageVue,
        BannerhorizontalVue
    },
    data() {
        return {
            apiUrl: 'https://apiy.htvann.com/api/',
            UserAuth: JSON.parse(localStorage.UserAuth),
            token: localStorage.token,
            phone: null,
            phonemsg: '',
            nom: null,
            nommsg: '',
            rue: null,
            ruemsg: '',
            zone: null,
            zonemsg: '',
            commune: null,
            communemsg: '',
            departement: null,
            departementmsg: '',
            formverify: false,
            communes: null,
            departements: null,
        }
    },
    created(){

        axios.get(this.apiUrl+'geo/departements',{headers: {'content-Type': 'application/json','Authorization': 'Bearer '+this.token}}).then((response)=>{
            this.departements = response.data;
            console.log(this.departements);
        });

        axios.get(this.apiUrl+'geo/communes',{headers: {'content-Type': 'application/json','Authorization': 'Bearer '+this.token}}).then((response)=>{
            this.communes = response.data;
            // console.log(this.communes);
        });

    },
methods:{
    ajouter(){

        this.loading();
        let body={
            nom: this.nom,
            rue: this.rue,
            zone: this.zone,
            sectioncommunal_id: this.sectcommunale,
            commune_id: this.commune,
            geodepartement_id: this.departement,
            utilisateur_id:this.user.id,
            telephone_adresse:this.telephone
        };
        console.log(body,"body body");

        this.apiservices.post((data)=>{
            console.log(data);
            if(data.status){
            this.navCtrl.push(CaissePage);
            }else{
            this.msg = "Formulaire incomplète";
            }
        },'adresse/user/add',body);

        },
        getGeoData(){

            this.apiservices.getsource((ApiData)=>{
            this.geo_departements = ApiData;
            },'geo/departements');

            this.apiservices.getsource((ApiData)=>{
            this.communes = ApiData;
            },'geo/communes');

            this.apiservices.getsource((ApiData)=>{
            this.section_communales = ApiData;
            },'geo/section_communales');

        },
        getDepartement(iddepartement){

            this.geo_departements = [];
            this.geo_departements.forEach(element => {
            if(iddepartement == element.id){
                this.geo_departements = element;
            }
            });

        },
        verifynom(){
            if(this.nom){
                this.nommsg = '';
                this.formverify = true;
            }else{
                this.nommsg = "Entrer nom";
                this.formverify = false;
            }
        },
        verifyphone(){
            if(this.phone){
                this.phonemsg = '';
                this.formverify = true;
            }else{
                this.phonemsg = "Entrer Telephone";
                this.formverify = false;
            }
        },
        verifyrue(){
            if(this.rue){
                this.ruemsg = '';
                this.formverify = true;
            }else{
                this.ruemsg = "Entrer # et rue";
                this.formverify = false;
            }
        },
        verifyzone(){
            if(this.zone){
                this.zonemsg = '';
                this.formverify = true;
            }else{
                this.zonemsg = "Entrer Zone";
                this.formverify = false;
            }
        },
        verifycommune(){
            if(this.commune){
                this.communemsg = '';
                this.formverify = true;
            }else{
                this.communemsg = "Choisir Commune";
                this.formverify = false;
            }
        },
        verifydepartement(){
            if(this.departement){
                this.departementmsg = '';
                this.formverify = true;
            }else{
                this.departementmsg = "Choisir Departement";
                this.formverify = false;
            }
        },
        saveadresse(){
            if(this.formverify){
                const body = {
                    nom: this.nom,
                    rue: this.rue,
                    zone: this.zone,
                    commune_id: this.commune,
                    geodepartement_id: this.departement,
                    utilisateur_id:this.UserAuth.id,
                    telephone_adresse:this.phone
                };

                axios.post(this.apiUrl+'adresse/user/add',body,{headers: {'content-Type': 'application/json','Authorization': 'Bearer '+this.token}})
                .then((response)=>{
                    if(response.status===200){
                        this.$router.push('/compte');
                    }else{
                        alert('Erreur')
                    }
                });

            }else{
                alert('Verifier les champs');
            }

        },
    }
}
</script>

<style>

</style>