<template>
  <!-- modal area start-->
  <div class="modal fade" id="modal_box_signup" tabindex="-1" role="dialog"  aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered-md" role="document">
            <div class="modal-content">
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                  <span aria-hidden="true">&times;</span>
                </button>
                <div class="modal_body">
                    <div class="container">
                        <div class="row">
                            <!--register area start-->
                            <div class="col-lg-12 col-md-12">
                                <div class="account_form register">
                                    <h2>Enregistrement</h2>
                                    <form action="#">
                                        <div class="row">
                                            <!-- <div class="col-lg-6 col-md-6 col-sm-12"> -->
                                                <p class="col-lg-6 col-md-6 col-sm-12">   
                                                    <label>Nom  <span>*</span></label>
                                                    <input class="form-control" type="text" v-model="nom" v-on:blur="nomverify()" v-on:keyup="nomverify()">
                                                    <small class="text-danger">{{nmmsg}}</small>
                                                </p>
                                                <p class="col-lg-6 col-md-6 col-sm-12">   
                                                    <label>Prénom  <span>*</span></label>
                                                    <input class="form-control" type="text" v-model="prenom" v-on:blur="prenomverify()" v-on:keyup="prenomverify()">
                                                    <small class="text-danger">{{prnmmsg}}</small>
                                                </p>
                                                <p class="col-lg-6 col-md-6 col-sm-12">   
                                                    <label>Sexe <span>*</span></label>
                                                    <select class="form-control" v-model="sexe" v-on:blur="sexeverify()" v-on:keyup="sexeverify()">
                                                        <option value="">Sexe</option>
                                                        <option value="1">Masculin</option>
                                                        <option value="0">Feminin</option>
                                                    </select>
                                                    <small class="text-danger">{{sxmsg}}</small>
                                                </p>
                                                <p class="col-lg-6 col-md-6 col-sm-12">   
                                                    <label>Naissance <span>*</span></label>
                                                    <input class="form-control" type="date" v-model="datenaiss" v-on:blur="naissanceverify()" v-on:keyup="naissanceverify()">
                                                    <small class="text-danger">{{nsscmsg}}</small>
                                                </p>
                                            <!-- </div>
                                            <div class="col-lg-6 col-md-6 col-sm-12"> -->
                                                <p class="col-lg-6 col-md-6 col-sm-12">   
                                                    <label>Téléphone  <span>*</span></label>
                                                    <input class="form-control" type="number" v-model="phone" v-on:blur="phoneverify()" v-on:keyup="phoneverify()">
                                                    <small class="text-danger">{{phmsg}}</small>
                                                </p>
                                                <p class="col-lg-6 col-md-6 col-sm-12">   
                                                    <label>E-mail  <span></span></label>
                                                    <input class="form-control" type="email" v-model="mail" v-on:blur="mailverify()" v-on:keyup="mailverify()">
                                                    <small class="text-danger">{{mamsg}}</small>
                                                </p>
                                                <p class="col-lg-6 col-md-6 col-sm-12">   
                                                    <label>Mot de Passe <span>*</span></label>
                                                    <input class="form-control" type="password" v-model="pwd" v-on:blur="pwdverify()" v-on:keyup="pwdverify()">
                                                    <small class="text-danger">{{pwdmsg}}</small>
                                                </p>
                                                <p class="col-lg-6 col-md-6 col-sm-12">   
                                                    <label>Mot de Passe <span>*</span></label>
                                                    <input class="form-control" type="password" v-model="confpwd" v-on:blur="cnfpwdverify()" v-on:keyup="cnfpwdverify()">
                                                    <small class="text-danger">{{cnfpwdmsg}}</small>
                                                </p>
                                                <small style="color:red;"> {{ mssg }} </small>
                                            <!-- </div> -->
                                            <div class="login_submit col-12" style="display: flex;flex-direction: column;justify-content: center;align-content: center;align-items: center;">
                                                <!-- <checkbox type="checkbox">J'accepte thermes et condition</checkbox>
                                                <input type="checkbox" class="form-control" name="vehicle1" value="Bike">
                                                <br /> -->
                                                <button v-if="allchamp==false" disabled type="button">Enregistrer</button>
                                                <button v-if="allchamp==true" data-dismiss="modal" type="button" @click="register()">Enregistrer</button>
                                            </div>
                                        </div>
                                    </form>
                                </div>    
                            </div>
                            <!--register area end-->
                        </div>     
                    </div>
                </div>    
            </div>
        </div>
    </div>
     <!-- modal area end-->
</template>

<script>
import Httpzs from "@/service/Httpzs"
import axios from 'axios'
// import router from '@/router'
const httpzs = new Httpzs();
export default {
    // const httpzs = new Httpzs();
    data() {
        return {
            allchamp: false,
            formverification: false,
            nom: null,
            nmmsg:'',
            prenom: null,
            prnmmsg: '',
            sexe: null,
            sxmsg: '',
            datenaiss:null,
            nsscmsg: '',
            phone: null,
            phmsg:'',
            mail: null,
            mamsg:'',
            pwd: null,
            pwdmsg: '',
            confpwd: null,
            cnfpwdmsg: '',
            pwcnfmsg:'',
            requette: null,
            mssg:'',
            body: null,
            apiUrl: 'https://apiy.htvann.com/api/',
        }
    },
    mounted() {
    },methods: {
        phoneverify(){
            if (this.phone ) {
                axios.get(this.apiUrl+'auth/verification/phone?phone='+this.phone)
                .then((response)=>{
                    // console.log(response);
                    if (response.status == 200) {
                        this.phmsg = 'Téléphone exist';
                        this.formverification =true;
                    }
                    
                });
                
                
            } else {
                this.verification =false;
                this.phmsg = "Ajouter le téléphone";
            }
        },
        mailverify(){
            axios.get(this.apiUrl+'auth/verification/mail?mail='+this.mail)
            .then((response)=>{
                // console.log(response);
                if (response.status == 200) {
                    this.mamsg = 'E-Mail exist';
                    this.formverification =true;
                }
                
            });
        },
        nomverify(){
            if (this.nom ) {
            this.nmmsg = "";
            this.formverification =true;
            } else {
            this.formverification =false;
            this.nmmsg = "Ajouter le nom";
            }
        },

        prenomverify(){
            if (this.prenom ) {
            this.prnmmsg = "";
            this.formverification =true;
            } else {
            this.formverification =false;
            this.prnmmsg = "Ajouter le prénom";
            }
        },

        sexeverify(){
            if (this.sexe ) {
            this.sxmsg = "";
            this.formverification =true;
            } else {
            this.formverification =false;
            this.sxmsg = "Choisir sexe";
            }
        },
        naissanceverify(){
            if (this.datenaiss ) {
            this.nsscmsg = "";
            this.formverification =true;
            } else {
            this.formverification =false;
            this.nsscmsg = "Ajouter date de naissance";
            }
        },
        pwdverify(){
            if (this.pwd ) {
            this.pwcnfmsg = "";
            this.formverification =true;
            } else {
            this.formverification =false;
            this.pwdmsg = "Mot de passe";
            }
        },
        cnfpwdverify(){
            if (this.confpwd ) {
            this.cnfpwcnfmsg = "";
            this.formverification =true;
            } else {
            this.formverification =false;
            this.cnfpwdmsg = "Confirmez mot de passe";
            }
            if (this.phone && this.nom && this.prenom && this.sexe && this.datenaiss && this.mail && this.pwd && this.confpwd) {
                this.allchamp = true;
                this.mssg = "";
            }else{
                this.allchamp = false;
                this.mssg = "Veuillez remplir tous les champs";
            }
        },
        register(){
            this.mssg = "Patientez svp...";
            if(this.pwd === this.confpwd){
                if (this.phone && this.nom && this.prenom && this.sexe && this.datenaiss && this.mail && this.pwd && this.confpwd) {
                    this.body = {
                        nom: this.nom,
                        prenom: this.prenom,
                        sexe: this.sexe,
                        // naissance: 
                        telephone: this.phone,
                        mail: this.mail,
                        password: this.confpwd
                    };
                    // console.log(this.body);
                    axios.post(this.apiUrl+'auth/register',this.body).then((response)=>{
                        if(response.status===200){
                            localStorage.setItem('UserAuth',JSON.stringify(response.data.user));
                            localStorage.setItem('token',response.data.token);
                            // console.log(response.data.user);
                            // router.push('home');
                            this.$router.push('/compte');
                        }
                    });
                }else{
                    this.mssg = "Veuillez remplir tous les champs";
                }
            }else{
                this.cnfpwdmsg = "Confirmez mot de passe SVP";
            }

        }
    },
}
</script>

<style>

</style>