<template>
    <Entete/>
    <!-- my account start  -->
    <section class="main_content_area">
        <div class="container">   
            <div class="account_dashboard product_column3">
                <div class="row">
                    <div class="col-sm-12 col-md-3 col-lg-3">
                        <!-- Nav tabs -->
                        <div class="dashboard_tab_button">
                            <ul role="tablist" class="nav flex-column dashboard-list">
                                <li><a href="#dashboard" data-toggle="tab" class="nav-link active">Tableau de bord</a></li>
                                <li> <a href="#orders" data-toggle="tab" class="nav-link">Commandes</a></li>
                                <!-- <li><a href="#downloads" data-toggle="tab" class="nav-link">Downloads</a></li> -->
                                <li><a href="#address" data-toggle="tab" @click="getAdresses()" class="nav-link">Adresses</a></li>
                                <li><a href="#account-details" data-toggle="tab" class="nav-link">Compte</a></li>
                                <li><a href="#account-securite" data-toggle="tab" class="nav-link">Sécurité</a></li>
                            </ul>
                        </div>    
                    </div>
                    <div class="col-sm-12 col-md-9 col-lg-9">
                        <!-- Tab panes -->
                        <div class="tab-content dashboard_content">
                            <div class="tab-pane fade show active" id="dashboard">
                                <h3>Tableau de Bord </h3><p>
                                    Depuis le tableau de bord de votre compte. vous pouvez facilement vérifier et afficher vos commandes récentes, gérer vos adresses de livraison et de facturation et modifier votre mot de passe et les détails de votre compte.
                                </p>
                            </div>
                            <div class="tab-pane fade" id="orders">
                                <h3>Vos Commandes</h3>
                                <div class="table-responsive">
                                    <table class="table">
                                        <thead>
                                            <tr>
                                                <th>Commande (s)</th>
                                                <th>Date</th>
                                                <th>Statut</th>
                                                <th>Total</th>
                                                <th>Actions</th>	 	 	 	
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr v-for="commande in commandes">
                                                <td></td>
                                                <td>May 10, 2018</td>
                                                <td><span class="success">Completed</span></td>
                                                <td>$25.00 for 1 item </td>
                                                <td><a href="cart.html" class="view">view</a></td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            <div class="tab-pane fade" id="downloads">
                                <h3>Downloads</h3>
                                <div class="table-responsive">
                                    <table class="table">
                                        <thead>
                                            <tr>
                                                <th>Product</th>
                                                <th>Downloads</th>
                                                <th>Expires</th>
                                                <th>Download</th>	 	 	 	
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td>Shopnovilla - Free Real Estate PSD Template</td>
                                                <td>May 10, 2018</td>
                                                <td><span class="danger">Expired</span></td>
                                                <td><a href="#" class="view">Click Here To Download Your File</a></td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            <div class="tab-pane" id="address">
                                <h4 class="billing-address">Adresse de Livraison</h4>
                                <button class="btn btn-secondary" @click="gotoaddadres()">Ajouter Adresse</button>
                                <div class="row">
                                    <div class="card col-lg-4 col-md-4 col-sm-12" style="margin:10px;" v-for="adresse in adresses">
                                        <address>
                                            <b>{{ adresse.nom }} &nbsp;&nbsp; <i v-if="adresse.actived === 1" class="zmdi zmdi-check text-success"> Par Défaut</i></b><br>
                                            {{ adresse.rue }}<br>
                                            {{ adresse.zone }} <br>
                                            {{ adresse.commune }}, 
                                            {{ adresse.geodepartement }}<br>
                                            Tel: {{ adresse.telephone_adresse }}
                                        </address>
                                        <div class="row">
                                            <!-- <a class="col-6 text-info">Modifier</a> -->
                                            <a class="col-6 text-info" v-if="adresse.actived === 0" @click="defaultadress(adresse.id)">Definir par Défaut</a>
                                        </div>
                                    </div>
                                </div> 
                            </div>
                            <div class="tab-pane fade" id="account-details">
                                <h3>Information du compte </h3>
                                <div class="login">
                                    <div class="login_form_container">
                                        <div class="account_login_form">
                                            <form action="#">
                                                <label>Prénom</label>
                                                <input type="text" v-model="prenom">
                                                <label>Nom</label>
                                                <input type="text" v-model="nom">
                                                <label>Sexe</label>
                                                <select class="form-control" v-model="sexe">
                                                    <option :selected="sexe === 1" value="1">Masculin</option>
                                                    <option :selected="sexe === 0" value="0">Féminin</option>
                                                </select><br/>
                                                <label>E-mail</label>
                                                <input type="text" v-model="mail">
                                                <label>Date de Naissance</label>
                                                <input type="date" v-model="naissance">
                                                <div class="save_button primary_btn default_button">
                                                    <button type="submit">Enregistrer</button>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="tab-pane fade" id="account-securite">
                                <h3>Sécurité Du Compte </h3>
                                <div class="login">
                                    <div class="login_form_container">
                                        <div class="account_login_form">
                                            <h5>Reinitialition mot de passe</h5>
                                            <hr>
                                            <form action="">
                                                <label>Ancien Mot de Passe</label>
                                                <input type="password" v-model="ancienpassword">
                                                <label>Nouveau Mot de Passe</label>
                                                <input type="password" v-model="nouveaupassword">
                                                <label>Confirmez Nouveau Mot de Passe</label>
                                                <input type="password" v-model="confirmpassword">
                                                <div class="save_button primary_btn default_button">
                                                    <button type="submit">Enregistrer</button>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>  
        </div>        	
    </section>			
    <!-- my account end   --> 
    <Bannerhorizontal/>
    <PiedpageVue/>
</template>

<script>
import axios from 'axios'
import Entete from './Entete.vue'
import BannerhorizontalVue from './Bannerhorizontal.vue'
import PiedpageVue from './Piedpage.vue';

export default {
    components: {
        Entete,
        PiedpageVue,
        BannerhorizontalVue
    },data() {
        return {
            apiUrl: 'https://apiy.htvann.com/api/',
            UserAuth: JSON.parse(localStorage.UserAuth),
            token: localStorage.token,
            commandes: null,
            adresses: null,
            prenom: JSON.parse(localStorage.UserAuth).prenom,
            nom: JSON.parse(localStorage.UserAuth).nom,
            naissance: JSON.parse(localStorage.UserAuth).naissance,
            mail: JSON.parse(localStorage.UserAuth).mail,
            sexe: JSON.parse(localStorage.UserAuth).sexe,
            ancienpassword: null,
            nouveaupassword: null,
            confirmpassword: null,
        }
    },created(){
        // getAdresses();
        console.log(this.UserAuth);
    },methods: {
        
        getAdresses(){
            axios.get(this.apiUrl+'adresses/user?user_id='+this.UserAuth.id,{headers: {'content-Type': 'application/json','Authorization': 'Bearer '+this.token}})
            .then((response)=>{
                this.adresses = response.data.adresses;
                // console.log(this.adresses);
            });
        },
        gotoaddadres(){
            this.$router.push('/dddrs');
        },
        defaultadress(id){
            const body = {
                utilisateur_id:this.UserAuth.id,
                id:id
            };

            axios.post(this.apiUrl+'adresses/modifier',body,{headers: {'content-Type': 'application/json','Authorization': 'Bearer '+this.token}})
            .then((response)=>{
                if(response.status === 200){
                    this.adresses = [];
                    this.getAdresses();
                }
            });
        },
        getcommandes(){
            axios.get(this.apiUrl+'commandes/user?user_id='+this.UserAuth.id,{headers: {'content-Type': 'application/json','Authorization': 'Bearer '+this.token}})
            .then((response)=>{
                this.commandes = response.data.Commandes;
            })
        },
        updatecompteinfo(){
            this.mssg = "Patientez svp...";
            
                this.body = {
                    nom: this.nom,
                    prenom: this.prenom,
                    sexe: this.sexe,
                    naissance: this.naissance,
                    telephone: this.phone,
                    mail: this.mail,
                };
                // console.log(this.body);
                axios.post(this.apiUrl+'update/infocompte',this.body,{headers: {'content-Type': 'application/json','Authorization': 'Bearer '+this.token}})
                .then((response)=>{
                    if(response.status===200){
                        // localStorage.setItem('UserAuth',JSON.stringify(response.data.utilisateur));
                        // localStorage.setItem('token',response.data.token);
                    }
                });
        },
        updatepassemot(){

            if(this.nouveaupassword == this.confirmpassword){
                this.body = {
                    nom: this.confirmpassword,
                    id: this.UserAuth.id,
                };
                // console.log(this.body);
                axios.post(this.apiUrl+'update/infocompte',this.body,{headers: {'content-Type': 'application/json','Authorization': 'Bearer '+this.token}})
                .then((response)=>{
                    if(response.status===200){
                        // localStorage.setItem('UserAuth',JSON.stringify(response.data.utilisateur));
                        // localStorage.setItem('token',response.data.token);
                    }
                });

            }

        }
        
    },

}
</script>

<style>

</style>