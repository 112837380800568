<template>
        <!--header area start-->
        <header class="header_area header_color">
            <!--header top start-->
            <div class="header_top">
                <div class="container">  
                    <div class="row align-items-center">
                        <div class="col-lg-4">
                        <div class="welcome_text">
                            <p>Bienvenue sur <span>HTVANN</span> <b v-if="UserAuth"> {{UserAuth.nom}}</b></p>
                        </div>
                        </div>
                        <div class="col-lg-8" v-if="UserAuth">
                            <div class="top_right text-right">
                                <ul>
                                    <!-- <li class="currency"><a href="#"><i class="fa fa-dollar"></i> US Dollar <i class="zmdi zmdi-caret-down"></i></a>
                                        <ul class="dropdown_currency">
                                            <li><a href="#">EUR – Euro</a></li>
                                            <li><a href="#">GBP – British Pound</a></li>
                                            <li><a href="#">INR – India Rupee</a></li>
                                        </ul>
                                    </li>
                                    <li class="language"><a href="#"><i class="zmdi zmdi-dribbble"></i> English1 <i class="zmdi zmdi-caret-down"></i></a>
                                        <ul class="dropdown_language">
                                            <li><a href="#">English</a></li>
                                            <li><a href="#">Germany</a></li>
                                        </ul>
                                    </li>  -->
                                    <li class="top_links"><a href="#"><i class="zmdi zmdi-account"></i> Mon compte <i class="zmdi zmdi-caret-down"></i></a>
                                        <ul class="dropdown_links">
                                            <li><router-link to="/compte">Mon compte </router-link></li>
                                            <!-- <li><router-link to="/address">Mes Adresses </router-link></li> -->
                                            <li><router-link to="/commande">Mes commandes</router-link></li>
                                            <li><i name="logout"></i><a @click="logout()">Déconnecter</a></li>
                                        </ul>
                                    </li>

                                </ul>
                            </div>
                        </div>

                        <div class="col-lg-8" v-if="UserAuth == null">
                            <div class="top_right text-right">
                                <ul>
                                    <li class="top_links"><a href="#"><i class="zmdi zmdi-account"></i> Salut Visiteur <i class="zmdi zmdi-caret-down"></i></a>
                                        <ul class="dropdown_links">
                                            <li><a data-toggle="modal" data-target="#modal_box_signup" ><i class="zmdi zmdi-assignment-account"></i> | S'Inscrire </a></li>
                                            <li><a data-toggle="modal" data-target="#modal_box_login" ><i class="zmdi zmdi-sign-in"></i> | Se Connecter </a></li>
                                        </ul>
                                    </li>

                                </ul>
                            </div>
                        </div>
                        
                    </div>
                </div>
            </div>
            <!--header top start-->
            <!--header center area start-->
            <div class="header_middle">
                <div class="container">
                    <div class="row align-items-center">
                        <div class="col-lg-3">
                            <div class="logo">
                                <a href="/"><img src="/img/logo.png" alt=""></a>
                            </div>
                        </div>
                        <div class="col-lg-9">
                            <div class="header_middle_inner">
                                <div class="search-container col-8">
                                    <form @submit="getchache()">
                                    <!-- <div class="hover_category">
                                            <select class="select_option" name="select" id="categori">
                                                <option selected value="1">Toutes Categories</option>
                                            </select>                        
                                    </div> -->
                                        <div class="search_box col-12">
                                            <input placeholder="Chercher produit..." type="text" v-model="chache">
                                            <button type="button" @click="getchache()"><i class="zmdi zmdi-search"></i></button> 
                                            <!-- <router-link :to="`/chprd/${chache}`">
                                                <button type="button"><i class="zmdi zmdi-search"></i></button> 
                                            </router-link> -->
                                        </div>
                                    </form>
                                </div>
                                <div class="mini_cart_wrapper">
                                    <a v-if="Panier.length == 0"><i class="zmdi zmdi-shopping-basket"></i> <span>0 Article</span> </a>
                                    <a v-if="Panier.length > 0"><i class="zmdi zmdi-shopping-basket"></i> <span>{{ Panier.length }} Article - {{ PrixTotal }} HTG</span> </a>
                                    <!--mini cart-->
                                    <div class="mini_cart">
                                        <div class="row col-12">
                                            <div class="cart_button col-12" style="float:right;">
                                                <router-link to="/panier">Voir Panier</router-link>
                                                <!-- <a href="checkout.html">Checkout</a> -->
                                            </div>
                                        </div>
                                        <br/>
                                        <div v-for="produi in Panier" class="cart_item">
                                            <div class="cart_img">
                                                <a href="#"><img :src="urlImg+produi.cover_img" alt=""></a>
                                            </div>
                                            <div class="cart_info">
                                                <a href="#">{{produi.sounom}}</a>

                                                <span class="quantity">Qte: {{ produi.quantite_ajouter }}</span>
                                                <span class="price_cart">Prix: {{ produi.prix }} HTG</span>
                                                <span class="price_cart">Rabais: {{ produi.rabais }}%</span>

                                            </div>
                                            <div class="cart_remove">
                                                <!-- <a href="#"><i class="ion-android-close"></i></a> -->
                                            </div>
                                        </div>
                                        <div class="mini_cart_table">
                                            <div class="cart_total">
                                                <span>Total:</span>
                                                <span class="price">{{ PrixTotal }} HTG</span>
                                            </div>
                                        </div>

                                        <div class="mini_cart_footer">
                                            <div class="cart_button">
                                                <router-link to="/panier">Voir Panier</router-link>
                                                <!-- <a href="checkout.html">Checkout</a> -->
                                            </div>
                                        </div>

                                    </div>
                                    <!--mini cart end-->
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <!--header center area end-->
            
            <!--header middel start-->
            <div class="header_bottom">
                <div class="container">
                    <div class="row align-items-center">
                        <div class="col-lg-3 main_menu">
                            <div class="categories_menu categori_three">
                                <div class="categories_title">
                                    <h2 class="categori_toggle">Départements</h2>
                                </div>
                                <div class="categories_menu_toggle">
                                    <ul> 
                                        <li><a href="#"><span><i class="zmdi zmdi-tv"></i></span> TV & Audio</a></li>
                                        <li><a href="#"><span><i class="zmdi zmdi-tv-list"></i></span> home funiture</a></li>
                                        <li><a href="#"><span><i class="zmdi zmdi-cake"></i></span> toys for kids</a></li>
                                        <li><a href="#"><span><i class="zmdi zmdi-memory"></i></span> Memory Cards</a></li>
                                        <li><a href="#"><span><i class="zmdi zmdi-case-download"></i></span> Living Room</a></li>
                                        <li class="hidden"><a href="shop-left-sidebar.html">New Sofas</a></li>
                                        <li class="hidden"><a href="shop-left-sidebar.html">Sleight Sofas</a></li>
                                        <li><a href="#" id="more-btn"><i class="fa fa-plus" aria-hidden="true"></i> More Categories</a></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-9">
                            <div class="main_menu menu_three header_position"> 
                                <nav>  
                                    <ul>

                                        <li >
                                            <router-link to="/"><i class="zmdi zmdi-home"></i> home </router-link>
                                        </li>
                                        <li v-if="UserAuth != null" class="mega_items"><router-link to="/maboutique"><i class="zmdi zmdi-shop"></i>Ma Boutique </router-link> </li>
                                        <li><router-link to="/about"><i class="zmdi zmdi-comments"></i> A PROPOS DE NOUS</router-link></li>
                                        <li><router-link to="/contact"><i class="zmdi zmdi-account-box-mail"></i>  Contactez-NOUS</router-link></li>
                                    </ul>  
                                </nav> 
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <!--header middel end-->
        
        </header>
        <!--header area end-->
        <!-- ============================================================================================================== -->
        <!-- Mobile -->
        <!-- ============================================================================================================== -->
        <!--Offcanvas menu area start-->
    
        <div v-if="showingmenumobil === 'active'" class="off_canvars_overlay active">
                
            </div>
            <div class="Offcanvas_menu">
                <div class="container">
                    <div class="row">
                        <div class="col-12">
                            <div class="canvas_open" @click="showmobilmenu()">
                                <span>MENU</span>
                                <a href="javascript:void(0)"><i class="ion-navicon"></i></a>
                            </div><br/>
                            <form  @submit="getchache()">
                                <!-- <div class="hover_category">
                                        <select class="select_option" name="select" id="categori">
                                            <option selected value="1">Toutes Categories</option>
                                        </select>                        
                                </div> -->
                                <div class="search_box canvas_open col-12">
                                    <input placeholder="Chercher produit..." type="text" v-model="chache">
                                    <button type="button" @click="getchache()"><i class="zmdi zmdi-search"></i></button> 
                                </div>
                            </form>
                            <div  v-if="showingmenumobil === 'active'" class="Offcanvas_menu_wrapper active">
                                <div class="canvas_close" @click="hidshowmobilmenu()">
                                      <a href="javascript:void(0)"><i class="ion-android-close"></i></a>  
                                </div>
                                <div class="welcome_text">
                                    <p>Bienvenue sur <span>HTVANN</span> <b v-if="UserAuth"> {{UserAuth.nom}}</b></p>
                               </div>
                               
                                <div class="top_right">
                                    <ul v-if="UserAuth ==null">
                                        <li class="top_links"><a href="#"><i class="zmdi zmdi-account"></i> Salut Visiteur <i class="zmdi zmdi-caret-down"></i></a>
                                            <ul class="dropdown_links">
                                                <li><a data-toggle="modal" data-target="#modal_box_signup" ><i class="zmdi zmdi-assignment-account"></i> | S'Inscrire </a></li>
                                                <li><a data-toggle="modal" data-target="#modal_box_login" ><i class="zmdi zmdi-sign-in"></i> | Se Connecter </a></li>
                                            </ul>
                                        </li>
                                    </ul>
                                    <ul v-if="UserAuth">
                                        <li class="top_links"><a href="#"><i class="zmdi zmdi-account"></i> Mon Compte <i class="zmdi zmdi-caret-down"></i></a>
                                            <ul class="dropdown_links">
                                                <li><router-link to="/compte">Mon compte </router-link></li>
                                                <!-- <li><router-link to="/address">Mes Adresses </router-link></li> -->
                                                <li><router-link to="/commande">Mes commandes</router-link></li>
                                                <li><i name="logout"></i><a @click="logout()">Déconnecter</a></li>
                                            </ul>
                                        </li>
                                    </ul>
                                </div>
                                
                                <div class="mini_cart_wrapper">
                                    <!-- <a href="javascript:void(0)"><i class="zmdi zmdi-shopping-basket"></i> <span>2items - $213.00</span> </a> -->
                                    <!-- <router-link to="/panier"><i class="zmdi zmdi-shopping-basket"></i> <span></span>Voir Panier</router-link> -->
                                    <router-link to="/panier" v-if="Panier.length == 0"><i class="zmdi zmdi-shopping-basket"></i> <span>0 Article</span> </router-link>
                                    <router-link to="/panier" v-if="Panier.length > 0"><i class="zmdi zmdi-shopping-basket"></i> <span>{{ Panier.length }} Article - {{ PrixTotal }} HTG</span> </router-link>
                                    <!--mini cart-->
                                     <div class="mini_cart">
                                        <div class="cart_item">
                                           <div class="cart_img">
                                               <a href="#"><img src="assets/img/s-product/product.jpg" alt=""></a>
                                           </div>
                                            <div class="cart_info">
                                                <a href="#">Condimentum Watches</a>
        
                                                <span class="quantity">Qty: 1</span>
                                                <span class="price_cart">$60.00</span>
        
                                            </div>
                                            <div class="cart_remove">
                                                <a href="#"><i class="ion-android-close"></i></a>
                                            </div>
                                        </div>
                                        <div class="cart_item">
                                           <div class="cart_img">
                                               <a href="#"><img src="assets/img/s-product/product2.jpg" alt=""></a>
                                           </div>
                                            <div class="cart_info">
                                                <a href="#">Officiis debitis</a>
                                                <span class="quantity">Qty: 1</span>
                                                <span class="price_cart">$69.00</span>
                                            </div>
                                            <div class="cart_remove">
                                                <a href="#"><i class="ion-android-close"></i></a>
                                            </div>
                                        </div>
                                        <div class="mini_cart_table">
                                            <div class="cart_total">
                                                <span>Subtotal:</span>
                                                <span class="price">$138.00</span>
                                            </div>
                                        </div>
        
                                        <div class="mini_cart_footer">
                                           <div class="cart_button">
                                                <a href="cart.html">View cart</a>
                                                <a href="checkout.html">Checkout</a>
                                            </div>
                                        </div>
        
                                    </div>
                                    <!--mini cart end-->
                                </div>
                                <div id="menu" class="text-left ">
                                     <ul class="offcanvas_main_menu">
                                        <li class="menu-item-has-children active">
                                            <a href="#">Menu</a>
                                            <ul class="sub-menu">
                                                <li ><router-link to="/"><i class="zmdi zmdi-home"></i> home </router-link></li>
                                                <li v-if="UserAuth != null" class="mega_items"><router-link to="/maboutique"><i class="zmdi zmdi-shop"></i>Ma Boutique </router-link> </li>
                                                <li><router-link to="/about"><i class="zmdi zmdi-comments"></i> A PROPOS DE NOUS</router-link></li>
                                                <li><router-link to="/contact"><i class="zmdi zmdi-account-box-mail"></i>  Contactez-NOUS</router-link></li>
                                            
                                            </ul>
                                        </li>
                                    </ul>
                                </div>
        
                                <div class="Offcanvas_footer">
                                    <span><a href="#"><i class="fa fa-envelope-o"></i> info@htvann.com</a></span>
                                    <ul>
                                        <li class="facebook"><a href="#"><i class="fa fa-facebook"></i></a></li>
                                        <li class="twitter"><a href="#"><i class="fa fa-twitter"></i></a></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <!--Offcanvas menu area end-->
    <Login/>
    <Register/>
</template>

<script>
import axios from 'axios';
import Login from '@/views/Login.vue'
import Register from '@/views/Register.vue'
import HomeViewVue from './HomeView.vue';
import router from '@/router';
// import {useRoute, useRouter} from 'vue-router'


export default {
    name:'Entete',
    components:{
        Login,
        Register,
    },
    data() {
        return {
            urlImg: 'https://apiy.htvann.com/',
            apiUrl: 'https://apiy.htvann.com/api/',
            token: null,
            Panier: [],
            PrixTotal: 0,
            UserAuth: null,
            chache: '',
            showingmenumobil: '',
        }
    },
    created() {
        if (localStorage.UserAuth) {
            this.UserAuth = JSON.parse(localStorage.UserAuth);
            this.token = localStorage.token;
        }
        
    },
    mounted() {
        if(localStorage.UserAuth){
            this.getPanier();
        }else{
            this.getPanierGuest();
        }
    },
    methods: {
        getPanier(){

            axios.get(this.apiUrl+'panier?user_id='+this.UserAuth.id,{headers: {'content-Type': 'application/json','Authorization': 'Bearer '+this.token}})
            .then((response)=>{
                this.Panier = [];
                this.PrixTotal = 0;
                response.data.Panier.forEach(element => {
                this.Panier.push(element);
                this.PrixTotal = this.PrixTotal + element.total;
                });
                localStorage.setItem('Panier',JSON.stringify(this.Panier));

            });

        },
        getPanierGuest(){

            this.Panier = [];
            this.PrixTotal = 0;
            // console.log(JSON.parse(localStorage.Panier));
            if(localStorage.Panier){
                JSON.parse(localStorage.Panier).forEach(element => {
                this.Panier.push(element);
                this.PrixTotal = this.PrixTotal + element.total;
                });
            }
            
        },
        getchache(){
            console.log(this.$route.fullPath);
            // console.log('/chprd/'+this.chache);
            // router.push({'chprd',this.chache});
            // if(String(this.$route.fullPath) === '/chprd/'+this.chache){
            //     window.location('/chprd/'+this.chache);
            // }else{
                router.push({name:'chprd',params: {artcl: this.chache}});
            // }
            
        },
        logout(){
            this.UserAuth = null;
            localStorage.clear();
            // window.location.reload();
            this.$router.push({path: '/'});
        },
        showmobilmenu(){
            this.showingmenumobil = 'active';
        },
        hidshowmobilmenu(){
            this.showingmenumobil = '';
        }
    }

};
</script>

<style>

</style>