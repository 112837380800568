import axios from "axios";

export default class Httpzs {

    apibaseurl = 'https://apiy.htvann.com/api/'; //Env-Prod
    baseUrlImg = 'https://apiy.htvann.com/'; //Env-Prod
  
    getImgUrl(){
      return this.baseUrlImg;
    }

    getApiUrl(){
      return this.apibaseurl;
    }

    GETZ(url:String) {
      console.log(url);
      let data:any;
      // setTimeout(()=>{
        axios.get(this.apibaseurl+url)
        .then((response)=>{
            data = response;
            console.log(data);
            
        });
        // return data;
      // }, 5000);
      // return data;
      
    }

    POSTZ(url:String,body:any){
      axios.post(this.apibaseurl+url,body)
      .then((response)=>{
        return response;
      })
    }
  
  }