<template>
    <Entete/>

    

          <!--shop  area start-->
    <div class="home_product_area home_product_three mb-50">
        <div class="container">
            <div class="row">
                <div class="card col-12" style="text-align:center; padding:30px; margin-top:10px;">
                    <h1 style="text-align:center; padding:30px; text-color:orange;">
                        {{ Boutique?.boutique }}
                    </h1>
                    <h4 style="text-color:orange;">{{ Boutique?.slogan }}</h4>
                    <small>Partager</small> <br>
                    <q-btn>
                        <ShareNetwork
                            network="facebook"
                            :url="'https://htvann.com/boutique/'+Boutique?.id*999666333"
                            :title="Boutique?.boutique"
                            :description="Boutique?.slogan"
                            quote=""
                            :hashtags="Boutique?.boutique"
                            
                        >
                        <a class="facebook">
                            <i class="fa fa-facebook"></i>
                            Facebook
                        </a>
                        </ShareNetwork>
                        &nbsp;&nbsp;&nbsp;
                        <ShareNetwork
                            network="whatsapp"
                            :url="'https://htvann.com/boutique/'+Boutique?.id*999666333"
                            :title="Boutique?.boutique"
                            :description="Boutique?.slogan"
                            quote=""
                            :hashtags="Boutique?.boutique"
                        >
                        <a class="whatsapp">
                            <i class="fa fa-whatsapp"></i>
                            Whatsapp
                        </a>
                        </ShareNetwork>
                        <!-- <ShareNetwork
                            network="messenger"
                            :url="'https://htvann.com/boutique/'+Boutique?.id*999666333"
                            :title="Boutique?.boutique"
                            :description="Boutique?.slogan"
                            quote=""
                            :hashtags="Boutique?.boutique"
                        >
                            <a class="messenger">
                                <i class="fa fa-facebook-messenger"></i>
                                Messenger
                            </a>
                        </ShareNetwork> -->
                        &nbsp;&nbsp;&nbsp;
                        <ShareNetwork
                            network="twitter"
                            :url="'https://htvann.com/boutique/'+Boutique?.id*999666333"
                            :title="Boutique?.boutique"
                            :description="Boutique?.slogan"
                            quote=""
                            :hashtags="Boutique?.boutique"
                        >
                            <a class="twitter">
                                <i class="fa fa-twitter"></i>
                                Twitter
                            </a>
                        </ShareNetwork>
                    </q-btn>
                </div>
                <div class="col-12">
                    <!--shop wrapper start-->
                    <!--shop toolbar start-->
                    <!-- <div class="shop_title">
                        <h1>shop</h1>
                    </div>
                    <div class="shop_toolbar_wrapper">
                        <div class="shop_toolbar_btn">

                            <button data-role="grid_3" type="button" class=" btn-grid-3" data-toggle="tooltip" title="3"></button>

                            <button data-role="grid_4" type="button"  class="active btn-grid-4" data-toggle="tooltip" title="4"></button>

                            <button data-role="grid_list" type="button"  class="btn-list" data-toggle="tooltip" title="List"></button>
                        </div>
                        <div class=" niceselect_option">

                            <form class="select_option" action="#">
                                <select name="orderby" id="short">

                                    <option selected value="1">Sort by average rating</option>
                                    <option  value="2">Sort by popularity</option>
                                    <option value="3">Sort by newness</option>
                                    <option value="4">Sort by price: low to high</option>
                                    <option value="5">Sort by price: high to low</option>
                                    <option value="6">Product Name: Z</option>
                                </select>
                            </form>


                        </div>
                        <div class="page_amount">
                            <p>Showing 1–9 of 21 results</p>
                        </div>
                    </div> -->
                    <!--shop toolbar end-->
                    <br>
                    <div class="shop_title">
                        <h3>Nos Produits</h3>
                    </div>
                     <div class="row no-gutters ">
                    
                      
                        <div class="row product_column4 owl ">
                            <div v-for="produit in produits" :key="produit.id" class="single_product col-lg-3 col-md-4 col-6">
                                <div class="product_thumb">
                                    <div style="height: 250px; overflow: hidden;">
                                        <router-link :to="`/produit/${produit.id}`">
                                            <img :src="`https://apiy.htvann.com/${produit.cover_img}`" alt="">
                                        </router-link>
                                    </div>

                                    <div v-if="produit.rabais > 0" class="label_product">
                                        <span class="label_sale">Rabais {{produit.rabais}} %</span>
                                    </div>

                                    <!-- <div class="quick_button">
                                        <a href=""  title="quick view"> <i class="zmdi zmdi-eye"></i></a>
                                    </div> -->
                                </div>
                                <div class="product_content">
                                    <div class="product_name" style="height: 35px; overflow: hidden;">
                                        <!-- <h3 ><router-link :to="{name: 'produit', query: { idp: 'tets'}}"> {{produitd.sounom}} </router-link></h3> -->
                                        <h3 ><router-link :to="`/produit/${produit.id}`"> {{produit.sounom}} </router-link></h3>
                                    </div>
                                    <div class="product_rating">
                                        <ul>
                                            <li><a href="#"><i class="zmdi zmdi-star-outline"></i></a></li>
                                            <li><a href="#"><i class="zmdi zmdi-star-outline"></i></a></li>
                                            <li><a href="#"><i class="zmdi zmdi-star-outline"></i></a></li>
                                            <li><a href="#"><i class="zmdi zmdi-star-outline"></i></a></li>
                                            <li><a href="#"><i class="zmdi zmdi-star-outline"></i></a></li>
                                        </ul>
                                    </div>

                                    <div v-if="produit.rabais > 0 " class="price_box">
                                        <span class="current_price">{{produit.prix-((produit.prix * produit.rabais)/100)}} HTG</span>
                                        <span class="old_price">{{produit.prix}} HTG</span>
                                    </div>

                                    <div v-if="produit.rabais == 0 " class="price_box">
                                        <span class="current_price">{{produit.prix}} HTG</span>
                                    </div>

                                    <div class="action_links">
                                        <ul>
                                            <!-- <li class="wishlist"><a href="wishlist.html" title="Add to Wishlist"><i class="fa fa-heart-o" aria-hidden="true"></i></a></li> -->
                                            <li class="add_to_cart">
                                                <router-link :to="`/produit/${produit.id}`"><i class="zmdi zmdi-shopping-cart-plus"></i> Ajouter </router-link>
                                            </li>
                                            <!-- <li class="compare"><a href="#" title="compare"><i class="zmdi zmdi-swap"></i></a></li> -->
                                        </ul>
                                    </div>
                                </div>
                            </div>

                        </div>
                        
                        
                    </div>

                    <!-- <div class="shop_toolbar t_bottom">
                        <div class="pagination">
                            <ul>
                                <li class="current">1</li>
                                <li><a href="#">2</a></li>
                                <li><a href="#">3</a></li>
                                <li class="next"><a href="#">next</a></li>
                                <li><a href="#">>></a></li>
                            </ul>
                        </div>
                    </div> -->
                    <!--shop toolbar end-->
                    <!--shop wrapper end-->
                </div>
            </div>
        </div>
    </div>
    <!--shop  area end-->


    <Bannerhorizontal/>
    <Piedpage/>
</template>

<script>
import router from '@/router'
import axios from 'axios'
import Entete from './Entete.vue'
import Bannerhorizontal from './Bannerhorizontal.vue'
import Piedpage from './Piedpage.vue'
import { useHead } from '@vueuse/head'

export default {
    name:'Boutique',
    components:{
        Entete,
        Bannerhorizontal,
        Piedpage,
    },props: ['id'],data() {
        return {
            UserAuth: null,
            token: localStorage.token,
            urlImg: 'https://apiy.htvann.com/',
            apiUrl: 'https://apiy.htvann.com/api/',
            Boutique: null,
            boutique_id:null,
            produits: [],
            Topcho: [],
        }
    },created() {
        this.boutique_id = this.id / 999666333;
        this.getboutique();
        
    },methods: {
        getboutique(){
            axios.get(this.apiUrl+'boutique?boutique_id='+this.boutique_id)
            .then((response)=>{
                this.Boutique = response.data[0];
                useHead({
                    title: this.Boutique.boutique,
                    description: this.Boutique.slogan,
                    meta: [
                        {
                            name: this.Boutique.boutique,
                            content: this.Boutique.slogan,
                        }
                    ],
                    
                });
                this.getProduits();
            });
        },
        getProduits(){

            axios.get(this.apiUrl+'boutique/produits?boutique_id='+this.boutique_id).then((response)=>{
                this.produits= response.data;
                axios.get(this.apiUrl+'boutique/topcho?boutique_id='+this.boutique_id).then((response)=>{
                    this.Topcho = response.data;
                });
            });

        }
    },
};
</script>

<style>

</style>