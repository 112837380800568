<template>
    <Entete/>
    <!--shopping cart area start -->
    <div class="shopping_cart_area mt-60">
        <div class="container">
            <form action="#">
                <div class="row">

                  <div class="col-12">
                      <!-- <div style="display:flex; justify-content: space-between;" padding>
                      <b>Total : {{PrixTotal | number : '2.1-2'}} HTG <br/>{{Panier.length}} Article(s)</b>
                      <button v-if="PrixTotal > 0" @click="methodepaiement(Panier,PrixTotal)" ion-button round small outline float-right>Placer Commande</button>
                    </div> -->
                    <div padding style="font-size: smaller; margin-top: -5px;">
                      <b>Adresse de livraison</b>
                      <p v-for="adresse in adresse">
                        +509 {{adresse.telephone_adresse}}<br/>
                        {{adresse.nom}}<br/>
                        {{adresse.rue}}, {{adresse.zone}}<br/>
                        {{adresse.section_communale}}, {{adresse.commune}}, {{adresse.geodepartement}}, Haïti
                        <button @click="gotoadresses()" style="color: dimgrey; " class="btn btn-default">Modifier Adresse</button>
                      </p>
                    </div>
                  </div>

                    <div class="col-12">
                        <div class="table_desc">
                            <div class="cart_page table-responsive">
                                <table>
                            <thead>
                                <tr>
                                    <th class="product_thumb">Image</th>
                                    <th class="product_name">Produit</th>
                                    <th class="product-price">Prix</th>
                                    <th class="product-price">Rabais</th>
                                    <!-- <th class="product-price">Prix - Rabais</th> -->
                                    <th class="product_quantity">Quantité</th>
                                    <th class="product_livraison">Livraison</th>
                                    <th class="product_total">Total</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="produit of Panier">
                                    <td class="product_thumb"><a href="#"><img :src="urlImg+produit.cover_img" alt=""></a></td>
                                    <td style="text-align: start;">
                                      <a href="#"> {{produit.sounom}} </a>
                                      <h3 v-if="produit.size_id">Size : {{produit.size}} </h3>
                                      <div v-if="produit.couleur_id" class="col-12">
                                        Couleur:
                                        <div class="col-3">
                                          <img src="{{urlImg}}{{produit.cover_img}}" alt="zo-th-1"/>
                                        </div>
                                      </div>
                                    </td>
                                    <td class="product-price">{{Intl.NumberFormat().format(produit.prix)}} HTG</td>
                                    <td class="product-price">{{produit.rabais}} %</td>
                                    <!-- <td class="product-price">{{produit.prixrabais}} HTG</td> -->
                                    <td class="product_quantity">
                                      <label>{{produit.quantite_ajouter}} </label>
                                        <!-- <div class="product_variant quantity">
                                            <a v-if="produit.quantite_ajouter > 1" class="btn btn btn-secondary col-4" style="color:#fff;" @click="rmquantite(produit,index)" >
                                            -
                                            </a>
                                            &nbsp;&nbsp;
                                            <a  v-if="produit.stock - produit.vente > 1" class="btn btn-warning col-4" @click="addquantite(produit,index)" >
                                            +
                                            </a>
                                        </div> -->
                                    </td>
                                    <td class="product_livraison">
                                      <p v-if="produit.Livraison <= 1" style="color:blue; font-size: small;">
                                        livraison Gratuite
                                      </p>
                                      <p v-if="produit.Livraison > 1" style="color:blue; font-size: small;">
                                        livraison : {{Intl.NumberFormat().format(produit.Livraison)}} HTG
                                      </p>
                                      <p>
                                        Dans {{produit.Jours}} jrs
                                      </p>
                                    </td>
                                    <td class="product_total">{{Intl.NumberFormat().format(produit.G_total)}} HTG</td>
                                </tr>
                            </tbody>
                        </table>
                            </div>
                            <div padding style="font-size: smaller; ">
                              <b>Adresse de livraison</b>
                              <p v-for="adresse in adresse">
                                +509 {{adresse.telephone_adresse}}<br/>
                                {{adresse.nom}}<br/>
                                {{adresse.rue}}, {{adresse.zone}}<br/>
                                {{adresse.section_communale}}, {{adresse.commune}}, {{adresse.geodepartement}}, Haïti
                                <button class="btn btn-default" @click="gotoadresses()" style="color: dimgrey; ">Modifier Adresse</button>
                              </p>

                            </div>
                        </div>
                     </div>
                 </div>
                 <!--coupon code area start-->
                <div class="coupon_area">
                    <div class="row">
                        <div class="col-lg-6 col-md-6">
                            <div class="coupon_code left">
                                <h3>Methode de paiement</h3>
                                <div class="row coupon_inner">

                                    <img class="col-3" src="/img/moncash.png" width="100px"/>

                                    <p class="col-9" style="text-align: justify;"><br/>Pour effectuer le paiement vous allez être rediriger vers l'interface du processeur de MonCash pour authoriser la transaction,
                                       et en suite la valider avec une confirmation OPT qui vous sera envoyé par SMS, soyez attentif pour tout bien faire</p>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-6" >
                            <div class="coupon_code right">
                                <h3>Panier Total</h3>
                                <div class="coupon_inner">

                                   <div class="cart_subtotal">
                                       <p>Soustotal</p>
                                       <p class="cart_amount"> {{Intl.NumberFormat().format(PrixTotal)}} HTG</p>
                                   </div>
                                   <div class="cart_subtotal">
                                    <p>Taxe</p>
                                    <p class="cart_amount"> {{Intl.NumberFormat().format(Taxe)}} HTG</p>
                                  </div>
                                   <div class="cart_subtotal">
                                    <p>Total</p>
                                    <p class="cart_amount"> {{Intl.NumberFormat().format(PrixTotal)}} HTG</p>
                                  </div>
                                   <div class="checkout_btn">
                                       <a href="#" @click="placerCommande(Panier,PrixTotal)">Placer Commande</a>
                                   </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <!--coupon code area end-->
            </form>
        </div>
    </div>
     <!--shopping cart area end -->
     <Bannerhorizontal/>
     <Piedpage/>
</template>

<script>
import axios from 'axios';
import Entete from './Entete.vue';
import Bannerhorizontal from './Bannerhorizontal.vue';
import Piedpage from './Piedpage.vue';

export default {
    components: {
        Entete,
        Piedpage,
        Bannerhorizontal
    },
    data() {
        return {
            urlImg: 'https://apiy.htvann.com/',
            apiUrl: 'https://apiy.htvann.com/api/',
            UserAuth: JSON.parse(localStorage.UserAuth),
            token: localStorage.token,
            adresse:'',
            Panier: [],
            livraison: null,
            Distance: null,
            DureeLivraison: null,
            Prixdistance: null,
            Prixvolume: null,
            Prixpoid: null,
            Qte: 0,
            msg: '',
            succes: false,
            dange: false,
            warn: false,
            PrixTotal: 0,
            TotalaPayer: null,
            Taxe: 0,
        }
    },
    mounted() {
        this.prefacture();
    },methods: {
        prefacture(){
            // window.location.href = 'https://www.google.com';
            console.log('test ok');
            axios.get(this.apiUrl+'adresse/user?user_id='+this.UserAuth.id,{headers: {'content-Type': 'application/json','Authorization': 'Bearer '+this.token}})
            .then((response)=>{

                if(response.data){
                    this.adresse = response.data.adresses;
                    axios.get(this.apiUrl+'commander?user_id='+this.UserAuth.id+'&depart_L_id='+this.adresse[0].geodepartement_id+'&commune_L_id='+this.adresse[0].commune_id,{headers: {'content-Type': 'application/json','Authorization': 'Bearer '+this.token}})
                    .then((response)=>{

                        if(response.data.content){
                        // this.Panier = [];
                        // this.PrixTotal = 0;
                        response.data.Panier.forEach(element => {
                            this.Panier.push(element);
                            this.PrixTotal = this.PrixTotal + element.G_total;
                        });
                        }
                    });
                }
            });
        },
        placerCommande(caisse,total){
            const body = {
                cout:total,
                taxe:0,
                utilisateur_id:this.UserAuth.id,
                adresse_id:this.adresse['0'].id,
                elements:caisse,
                typ:'MonCash'
            };
            console.log(body);
            axios.post(this.apiUrl+'commande/placer',body,{headers: {'content-Type': 'application/json','Authorization': 'Bearer '+this.token}})
            .then((response)=>{
                console.log(response);
                console.log(response.data.url);
            // setTimeout(() => {
            //     // loading.dismiss();
            // }, 100);
            if(response.status == 200){

                // this.navCtrl.setRoot(CommandePage);
                console.log(response.data.url);
                // this.loadmoncash(response.url);
                window.location.href = response.data.url;
                // router.

            }else{
                // this.msg = "Problème de connexion";
            }
            });

        }
    },
}
</script>

<style>

</style>