import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router';
import HomeView from '../views/HomeView.vue';
import AboutView from '../views/AboutView.vue';
import Contacter from '../views/Contacter.vue';
import Maboutique from '../views/Maboutique.vue';
import Boutique from '../views/Boutique.vue';
import Produit from '../views/Produit.vue';
import Panier from '../views/Panier.vue';
import Compte from '../views/Compte.vue';
import Ajouteradresse from '../views/Ajouteradresse.vue';
import Chercher from '../views/Chercher.vue';
import Commander from '../views/Commander.vue';
import Commande from '../views/Commande.vue';
import NotFound from '../views/NotFound.vue';
import CreerBoutique from '../views/CreerBoutique.vue';

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/about',
    name: 'about',
    component:AboutView
  },
  {
    path: '/contact',
    name: 'contact',
    component:Contacter
  },
  {
    path: '/maboutique',
    name: 'maboutique',
    component:Maboutique
  },
  {
    path: '/boutique/:id',
    name: 'boutique',
    component: Boutique,
    props:true
  },
  {
    path: '/crrbtq',
    name: 'creerboutique',
    component: CreerBoutique
  },
  {
    path: '/produit/:id',
    name: 'produit',
    component: Produit,
    props:true
  },
  {
    path: '/panier',
    name: 'panier',
    component: Panier,
  },
  {
    path: '/compte',
    name: 'compte',
    component: Compte,
  },
  {
    path: '/dddrs',
    name: 'dddrs',
    component: Ajouteradresse
  },{
    path: '/chprd/:artcl',
    name: 'chprd',
    component: Chercher,
    props:true,
  },{
    path: '/commander',
    name: 'commander',
    component: Commander
  },{
    path: '/commande',
    name: 'commande',
    component: Commande
  },{
    path: '/:pathMatch(.*)*',
    name: 'notfound',
    component: NotFound
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
